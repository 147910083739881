import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { interval } from 'rxjs/internal/observable/interval';
import { concatMap, startWith } from 'rxjs/operators';
import { MessageTopic } from '@fulfil0518/fulfil-api-libs/pubsub';
import isEmpty from 'lodash/isEmpty';
import { lastValueFrom } from 'rxjs';
import { CloudApiService } from './cloud-api.service';

export interface IPubsub {
  to: string;
  from: string;
  topic: MessageTopic;
  body: string | any;
  acknowledged: boolean;
  started: boolean;
  completed: boolean;
  [key: string]: any;
}

export const messages = Object.values(MessageTopic);

@Injectable()
export class PubsubService {
  constructor(private cloudApi: CloudApiService) {}

  /**
   * Pull all message without acknowledgements of last one hour.
   * @param to sender username
   * @param from receiver username
   * @param topic returns message array
   */
  topicSubscribe(topic: string | string[]) {
    return interval(2000).pipe(
      startWith(0),
      concatMap(() =>
        this.pull({ to: 'dashboard.factory_operator_mobile_ui', topic })
      )
    );
  }

  pull(params: {
    to?: string;
    from?: string;
    topic?: string | string[];
    priority?: number;
  }): Promise<IPubsub[]> {
    return lastValueFrom(
      this.cloudApi.get<IPubsub[]>(
        `pubsub?${new HttpParams({ fromObject: params }).toString()}`
      )
    );
  }

  getByTopic(topic: string, params: { offset?: number; limit?: number }) {
    topic = isEmpty(topic) ? 'all' : topic;
    return lastValueFrom(
      this.cloudApi.get<{ data: IPubsub[]; totalRows: number }>(
        `pubsub/topic/${topic}?${new HttpParams({
          fromObject: params,
        }).toString()}`
      )
    );
  }

  /**
   * Get full message info by specific message id.
   * @param id message unique id
   */
  getMessageById(id: string): Promise<IPubsub> {
    return lastValueFrom(this.cloudApi.get<IPubsub>(`pubsub/${id}`));
  }

  /**
   * Publish message to specific topic
   * @param to sender username
   * @param from receiver username
   * @param topic pubsub topic name
   * @param body pubsub message body
   */
  publish(to: string, from: string, topic: string, body: string) {
    return lastValueFrom(
      this.cloudApi.post<IPubsub>('pubsub', { to, from, topic, body })
    );
  }

  /**
   * Publish message to specific topic
   * @param to sender username
   * @param from receiver username
   * @param topic pubsub topic name
   * @param body pubsub message body
   */
  publishV2(to: string, from: string, topic: string, body: string) {
    return this.cloudApi.post<IPubsub>('pubsub', { to, from, topic, body });
  }

  /**
   * Send acknowledgement to specific pubsub message by id.
   * @param id message unique id
   */
  ack(id: string): Promise<IPubsub> {
    return lastValueFrom(this.cloudApi.post<IPubsub>(`pubsub/ack/${id}`, null));
  }

  /**
   * Update pubsub message with start flag by message id.
   * @param id message unique id
   */
  start(id: string): Promise<any> {
    return lastValueFrom(this.cloudApi.post<any>(`pubsub/start/${id}`, null));
  }

  /**
   * Update pubsub message with complete flag by message id.
   * @param id message unique id
   */
  complete(id: string): Promise<IPubsub> {
    return lastValueFrom(
      this.cloudApi.post<IPubsub>(`pubsub/complete/${id}`, null)
    );
  }
}
