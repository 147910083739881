import { Component } from '@angular/core';

@Component({
  selector: 'app-header-outlet',
  template: `
    <mat-toolbar class="toolbar">
      <div class="header">
        <div id="app-header-outlet--start"></div>
        <div id="app-header-outlet--end"></div>
      </div>
    </mat-toolbar>
  `,
  styleUrls: ['./header-outlet.component.scss'],
})
export class HeaderOutletComponent {}
