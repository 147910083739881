import { Component, OnInit } from '@angular/core';
import { SharedService } from './core';
import { WorkstationStyleService } from './core/services/workstation-style.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isLoaderVisible = false;
  constructor(
    private sharedService: SharedService,
    private workstationStyleService: WorkstationStyleService
  ) {}

  ngOnInit() {
    this.removeLoader();
    this.sharedService.loader.subscribe(
      (response: { isLoaderVisible: boolean }) => {
        setTimeout(() => {
          if (response.isLoaderVisible !== this.isLoaderVisible) {
            this.isLoaderVisible = response.isLoaderVisible;
          }
        }, 0);
      }
    );
  }

  private removeLoader() {
    const loader = document.getElementById('fulfil-bootstrap-loader');
    if (loader) {
      loader.parentElement?.removeChild(loader);
    }
  }
}
