import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PortalModule } from '@angular/cdk/portal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header.component';
import { HeaderStartComponent } from './header-start.component';
import { HeaderEndComponent } from './header-end.component';
import { HeaderOutletComponent } from './header-outlet.component';

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderStartComponent,
    HeaderEndComponent,
    HeaderOutletComponent,
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    PortalModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [HeaderComponent, HeaderOutletComponent],
})
export class HeaderModule {}
