import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { NavListComponent } from './nav-list.component';
import { WebVersionPipe } from '../../../pipes/web-version.pipe';

@NgModule({
  declarations: [NavListComponent],
  imports: [
    CommonModule,
    MatListModule,
    RouterModule,
    MatIconModule,
    WebVersionPipe,
    MatLegacyButtonModule,
  ],
  exports: [NavListComponent],
})
export class NavListModule {}
