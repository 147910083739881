<span class="job-text"
  >{{ laborJobType | titlecase }}
  <ng-container *ngIf="(job | async)?.status === LaborJobStatus.InProgress">
    - {{ elapsedMinutes | async }} min elapsed
  </ng-container>
  <ng-container *ngIf="(job | async)?.status !== LaborJobStatus.InProgress">
    - not started
  </ng-container>
</span>
<button
  *ngIf="(job | async)?.status === LaborJobStatus.InProgress"
  mat-raised-button
  color="warn"
  [disabled]="isCompleting"
  (click)="complete()"
>
  Complete
</button>
<button
  *ngIf="(job | async)?.status !== LaborJobStatus.InProgress"
  mat-raised-button
  color="primary"
  (click)="start()"
  [disabled]="isStarting"
>
  Start
</button>
