<h1 mat-dialog-title>Application Settings</h1>
<div mat-dialog-content class="dialog-content">
  <p>
    These settings are stored in the browser's local storage. Click the save
    button for setting you want to update.
  </p>
  <section>
    <h3>Login Redirect URL</h3>
    <p>Set the login redirect URL as the current page.</p>
    <br />
    <label>Saved Login Redirect URL:</label>
    <h4>{{ defaultRedirect }}</h4>
    <label>Current Page:</label>
    <h4>{{ currentPage }}</h4>
    <button
      mat-raised-button
      color="primary"
      (click)="handleSaveRedirectClick()"
    >
      Save Login Redirect URL
    </button>
  </section>
  <section>
    <h3>Auto Logout</h3>
    <p>
      Automatically log the user out after a period of inactivity (no mouse
      moves or keyboard input).
    </p>
    <br />
    <mat-slide-toggle
      color="accent"
      [(ngModel)]="autoLogoutEnabled"
      (change)="handleAutoLogoutEnabledChange($event.checked)"
    >
      Auto Logout Enabled</mat-slide-toggle
    >
    <br />
    <br />
    <mat-form-field class="auto-logout-time" *ngIf="autoLogoutEnabled">
      <input
        matInput
        type="number"
        placeholder="Auto Logout Time in Minutes"
        [formControl]="autoLogoutTimeMinutes"
        min="0"
        max="1000"
      />
    </mat-form-field>
    <br />
    <button
      mat-raised-button
      color="primary"
      (click)="handleSaveAutoLogoutClick()"
    >
      Save Auto Logout
    </button>
  </section>
  <section>
    <h3>Enter Keydown Debugger</h3>
    <p>
      Enables debugging mode on the manual load page for induction. When the
      physical blue button causes a next to occur a message is shown.
    </p>
    <br />
    <mat-slide-toggle
      color="accent"
      [formControl]="enterKeydownDebugger"
      (change)="handleSaveEnterKeydownEnabled($event.checked)"
    >
      Enter Keydown Debugger Enabled</mat-slide-toggle
    >
    <br />
    <br />
  </section>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="dialogRef.close()">Close</button>
</div>
