import { ErrorHandler, Injectable } from '@angular/core';

// shameless copy paste from
// https://stackoverflow.com/questions/49198268/how-to-overcome-loading-chunk-failed-with-angular-lazy-loaded-modules

@Injectable()
export class ChunkLoadErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    console.error('Chunk load error handler: ');
    console.error(error);
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
  }
}
