import { Injectable } from '@angular/core';
import { CloudApiService } from './cloud-api.service';
import {
  UserReport,
  getRelativePostUrl,
  getReportBody,
} from '@fulfil0518/fulfil-api-libs/user-report/index';
import { AllReports } from '@fulfil0518/fulfil-api-libs/user-report/report-types';

@Injectable()
export class UserReportService {
  constructor(private cloudApi: CloudApiService) {}

  /**
   * Issue a report on behalf of a user, used when backend needs to know stuff
   * about a user's action taken but there's not otherwise an endpoint to call about it
   * @param action
   * @param extraReportParams
   */
  report<R extends AllReports>(
    action: R['action'],
    extraReportParams: Omit<R, 'action' | 'triggered_at'>
  ) {
    const baseReport: UserReport<string> = {
      action,
      triggered_at: Date.now(),
    };

    const report = {
      ...baseReport,
      ...extraReportParams,
    } as R;
    return this.cloudApi.post(
      getRelativePostUrl(report),
      getReportBody(report)
    );
  }
}
