import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FactoryOrderParams } from '@frapi/orders';
import { FrapiResponse } from '../types';
import { map } from 'rxjs/operators';
import { FrapiService } from './frapi.service';
import { MultiStoreService } from './multi-store.service';
import { FactoryBag } from '@frapi/orders/factory-orders';

@Injectable()
export class BagService {
  constructor(
    private frapiService: FrapiService,
    private multiStoreService: MultiStoreService
  ) {}

  getBagDetails(
    factoryOrderParams: FactoryOrderParams
  ): Observable<FactoryBag[]> {
    let params = '';
    // .get() does not take in a params objects and only takes in a URL and headers
    // we have to manually append query params to the route
    Object.entries(factoryOrderParams).forEach((value) => {
      params += `${value[0]}=${value[1]}&`;
    });
    const url = `bags/bag-details?${params}`;
    return this.frapiService
      .get<FrapiResponse<FactoryBag[]>>(
        this.multiStoreService.getCurrentFacilityIdentifier(),
        url
      )
      .pipe(map((response) => response.result));
  }
}
