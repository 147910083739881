import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Order } from '@fulfil0518/fulfil-api-libs/orders';
import { MultiStoreService } from 'src/app/core/services/multi-store.service';
import { OrderFilters } from 'src/app/shared/components/order-filters/order-filters.component';
import { HttpParams } from '@angular/common/http';
import { CloudApiService } from 'src/app/core/services/cloud-api.service';
import { lastValueFrom } from 'rxjs';
import {
  DispenseEvent,
  DispenseEventType,
  FactoryDispenseEvent,
  GroundTruthDispenseEvent,
} from '@fulfil0518/fulfil-api-libs/dispense/event';
import { ABISDispense } from './types';

type OrderWithDispenseEvents = Order & { dispenseEvents?: DispenseEvent[] };

export function isFactoryDispenseEvent(
  dispense: DispenseEvent | undefined | null
): dispense is FactoryDispenseEvent {
  return (
    _.get(dispense, 'dispense_event_type') ===
    DispenseEventType.FACTORY_CONTROL_DISPENSE
  );
}

export function isGroundTruthDispenseEvent(
  dispense: DispenseEvent
): dispense is GroundTruthDispenseEvent {
  return (
    _.get(dispense, 'dispense_event_type') ===
    DispenseEventType.GROUND_TRUTH_CONCLUSION
  );
}

/**
 * turns undefined, null, empty string, and NaN to null, everything else is parsed as a number
 * and if the result of parsing is NaN, null is returned. Everything else returns the number
 * @param val - thing to translate
 */
function toValidNumber(val: string | number | undefined | null): number | null {
  if (val !== 0 && !val) {
    return null;
  }
  const num = _.toNumber(val);
  return _.isFinite(num) ? num : null;
}

@Injectable()
export class DispenseEventService {
  constructor(
    private cloudApi: CloudApiService,
    private multiStoreService: MultiStoreService
  ) {}

  /**
   * Because this is a lazy loaded module and the :orderId
   * is defined in AbisModule it is not straightforward
   * to get the orderId from ActivatedRoute
   */
  parseOrderIdFromUrl(): number | null {
    const parts = location.href.split('/');
    const i = parts.findIndex((part) => part === 'order');
    if (i === -1) {
      return null;
    }
    // @ts-expect-error ts(2532)
    return +parts[i + 1];
  }

  getOrdersWithDispenses(
    params: OrderFilters & { factory_dispense_id?: string }
  ): Promise<{ data: OrderWithDispenseEvents[] }> {
    let _params: HttpParams;
    const orderId = params?.order_id || this.parseOrderIdFromUrl();
    if (orderId) {
      _params = new HttpParams({
        fromObject: { order_id: orderId, offset: 0, limit: 1 },
      });
    } else {
      _params = new HttpParams({
        // @ts-expect-error ts(2322)
        fromObject: _.omitBy({ ...params }, _.isNil),
      });
    }
    const url = `dispense-event/orders?${_params.toString()}`;
    return lastValueFrom(this.cloudApi.get(url));
  }

  getDispenses({
    factory_dispense_id,
    dispense_event_type,
  }: {
    factory_dispense_id?: string;
    dispense_event_type?:
      | DispenseEventType.FACTORY_CONTROL_DISPENSE
      | DispenseEventType.GROUND_TRUTH_CONCLUSION;
  }): Promise<{ data: { count: number; rows: any[] } }> {
    if (!factory_dispense_id && !dispense_event_type) {
      throw new Error('At least one param is required to retrieve dispenses');
    }
    const where = {
      ...(factory_dispense_id && { factory_dispense_id }),
      ...(dispense_event_type && { dispense_event_type }),
    };
    const url = `dispense-event?where=${JSON.stringify(where)}`;
    return lastValueFrom(
      this.cloudApi.get<{ data: { count: number; rows: any[] } }>(url)
    );
  }

  async updateDispenseEvent(
    dispense: ABISDispense
  ): Promise<{ data: ABISDispense }> {
    let url =
      'dispense-event' +
      `?factory_dispense_id=${dispense.factory_dispense_id}` +
      `&dispense_event_type=${dispense.dispense_event_type}` +
      `&factory_bag_id=${dispense.factory_bag_id}` +
      `&order_id=${dispense.order_id}` +
      `&timestamp=${dispense.timestamp}` +
      `&metadata=${JSON.stringify(dispense.data)}`;

    if (dispense.facility_id) {
      const facilities = await lastValueFrom(
        this.multiStoreService.facilities$
      );
      const facility_identifier = facilities.find(
        (f) => f.id === dispense.facility_id
      )?.identifier;
      if (!facility_identifier) {
        throw new Error(`Invalid facility id: ${dispense.facility_id}`);
      }
      url += `&facility_identifier=${facility_identifier}`;
    }

    return lastValueFrom(this.cloudApi.post(url, {}));
  }

  /**
   * this is used to ensure that all of the values for a dispense are of the proper type
   * @param dispense
   */
  // @ts-expect-error ts(2366)
  convertDispenseNumberValues(dispense: ABISDispense): ABISDispense {
    if (isGroundTruthDispenseEvent(dispense)) {
      return this.convertGroundTruthDispenseNumberValues(dispense);
    } else if (isFactoryDispenseEvent(dispense)) {
      return this.convertFactoryDispenseNumberValues(dispense);
    }
  }

  // how to get these from the type??
  convertGroundTruthDispenseNumberValues = this.convertNumbers([
    'order_id',
    'data.target_product_lane_count_post_dispense',
    'data.actual_floor_items',
    'data.actual_bag_items',
  ]);

  convertFactoryDispenseNumberValues = this.convertNumbers(['order_id']);

  convertNumbers(paths: string[]): (_: ABISDispense) => ABISDispense {
    return (dispense) => {
      const obj = { ...dispense };
      paths.forEach((path) => {
        _.set(obj, path, toValidNumber(_.get(obj, path)));
      });
      return obj;
    };
  }
}
