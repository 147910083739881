import { Injectable } from '@angular/core';
import {
  catchError,
  from,
  Observable,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { environment } from '../../../environments/environment';

// From https://christianlydemann.com/implementing-dynamic-environments-in-angular-for-avoiding-one-build-per-environment/
@Injectable({
  providedIn: 'root',
})
export class DynamicEnvVarsService {
  /**
   * Method that is called when the app is bootstrapped
   */
  //
  constructor() {}
  public load$: Observable<void> = from(fetch('assets/app-config.json')).pipe(
    switchMap((response) => response.json()),
    tap((config) =>
      Object.keys(config).forEach((configKey) => {
        // @ts-expect-error ts(7053)
        environment[configKey] = config[configKey];
      })
    ),
    catchError((e) => {
      console.warn(`Could not load dynamic environment vars!`, e);
      return of(undefined);
    }),
    shareReplay(1)
  );
}
