import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { getDecodedJwt } from './auth.service';

export enum SentryTag {
  StockCaseId = 'StockCaseId',
  TlrMongoId = 'TlrMongoId',
}

type LogMessageParams = {
  /**
   * Name of Component, Service, Function, etc.
   */
  context: string;
  /**
   * Arbitrary JSON data to include
   */
  data?: { [key: string]: unknown };
  /**
   * Optionally include tags that are searchable
   */
  tags?: Partial<Record<SentryTag, number | string>>;
  eventName: string;
};

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor() {}

  info(params: LogMessageParams) {
    this.captureEvent(params, 'info');
  }

  debug(params: LogMessageParams) {
    this.captureEvent(params, 'debug');
  }

  error(params: LogMessageParams) {
    this.captureEvent(params, 'error');
  }

  /** Log a distribution value in milliseconds */
  logDurationMS(name: string, value: number, tags?: Record<string, any>) {
    Sentry.metrics.distribution(name, value, {
      unit: 'millisecond',
      tags,
    });
  }

  private user() {
    const jwtData = getDecodedJwt();
    return {
      email: jwtData?.data?.email || 'undefined',
    };
  }

  private captureEvent(
    params: LogMessageParams,
    level: 'debug' | 'info' | 'error'
  ) {
    Sentry.captureEvent({
      user: this.user(),
      tags: {
        context: params.context,
        dashboardEvent: `${params.eventName}`,
        ...(params.tags || {}),
      },
      level,
      extra: {
        ...params.data,
      },
    });
  }
}
