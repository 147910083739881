import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NavService } from 'src/app/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { combineLatestWith, map, shareReplay, takeUntil } from 'rxjs/operators';
import { VendorTheme } from '@fulfil0518/fulfil-api-libs/vendor-store';

import { OnDestroySubject } from 'src/app/shared/observables/on-destroy-subject';
import { AppVersionService } from '../../../../core/services/app-version.service';
import { MultiStoreService } from '../../../../core/services/multi-store.service';
import { NotifyService } from '../../../../core/services/notify.service';
import { AppSettingsDialogComponent } from '../../app-settings-dialog/app-settings-dialog.component';
import { FrapiVersionService } from '../../../../core/services/frapi-version.service';

// sidenav color constants
const SIDENAV_SIMULATION_BACKGROUND_COLOR = '#0E2F37';
const SIDENAV_STAGING_BACKGROUND_COLOR = '#0E2F37';
const SIDENAV_DEFAULT_BACKGROUND_COLOR = '#8b8b8b';
const SIDENAV_PRODUCTION_BACKGROUND_COLOR = 'var(--fulfil-green)';

@Component({
  selector: 'app-nav-list',
  templateUrl: './nav-list.component.html',
  styleUrls: ['./nav-list.component.scss'],
})
export class NavListComponent implements OnInit, OnDestroy {
  #onDestroy$ = new OnDestroySubject();
  menuIsOpen = false;
  currentVersion = '';
  newVersion = 'latest';
  showNewVersionButton = false;
  logoAreaBackgroundColor$: Observable<string>;
  vendorTheme$: Observable<VendorTheme | null>;
  fullLogoUrl$: Observable<string>;
  sideNavBackgroundColor$: Observable<string>;
  frapiVersion: string | null = null;
  @Output() clickedLink: EventEmitter<boolean> = new EventEmitter();
  // @ts-expect-error ts(2564)
  @ViewChild('matSidenav') public matSidenav: {
    close: () => void;
    toggle: () => void;
  };

  public isSimulation = !!environment.SIMULATION_NAME;
  public sidenavRoutes: Routes = [];
  public envDisplayName =
    environment.SIMULATION_NAME || environment.ENV_DISPLAY_NAME;
  public accountManagementUrl = `${environment.KC_BASE_URL}/realms/fulfil/account`;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    public multiStoreService: MultiStoreService,
    public navService: NavService,
    private appVersionService: AppVersionService,
    private notifyService: NotifyService,
    private dialog: MatDialog,
    private frapiVersionService: FrapiVersionService
  ) {
    this.currentVersion = this.appVersionService.localVersion;
    this.vendorTheme$ = this.route.queryParams.pipe(
      map((params) => (params.vendorstore as string) || null),
      combineLatestWith(
        this.multiStoreService.vendorStoreContext$,
        this.multiStoreService.vendorStores$
      ),
      map(([vendorStoreQueryParam, vendorStoreIdentifier, vendorStores]) => {
        if (vendorStoreQueryParam === null) {
          return null;
        }
        return (
          vendorStores.find(
            ({ identifier }) => identifier === vendorStoreIdentifier
          )?.vendor.theme || null
        );
      }),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
    this.fullLogoUrl$ = this.vendorTheme$.pipe(
      map((theme) =>
        theme === null ? 'assets/images/logo_white.png' : theme.logo_url
      )
    );
    this.sideNavBackgroundColor$ = this.vendorTheme$.pipe(
      map((theme) =>
        theme === null || !this.isProduction()
          ? this.defaultSideNavBackgroundColor()
          : theme.primary_color
      ),
      this.notifyService.catchError(
        'Error fetching partner theme',
        this.defaultSideNavBackgroundColor()
      )
    );

    this.logoAreaBackgroundColor$ = this.vendorTheme$.pipe(
      map((theme) =>
        theme === null
          ? this.defaultSideNavBackgroundColor()
          : theme.primary_color
      )
    );
    this.menuIsOpen = localStorage.getItem('menuIsOpen') === 'true';
  }

  toggle() {
    this.matSidenav.toggle();
  }

  async ngOnInit() {
    await this.navService.fetchPinnedNavItems();
    this.appVersionService.isNewVersionDetected$
      .pipe(takeUntil(this.#onDestroy$))
      .subscribe((newVersion) => {
        this.newVersion = newVersion;
        this.showNewVersionButton = true;
      });

    this.frapiVersionService
      .intervalGetFrapiVersion()
      .pipe(takeUntil(this.#onDestroy$))
      .subscribe((version) => {
        this.frapiVersion = version;
      });
  }

  defaultSideNavBackgroundColor(): string {
    if (environment.SIMULATION_NAME) {
      return `${SIDENAV_SIMULATION_BACKGROUND_COLOR} !important`;
    }
    switch (environment.name) {
      case 'staging':
        return `${SIDENAV_STAGING_BACKGROUND_COLOR} !important`;
      case 'production':
        return `${SIDENAV_PRODUCTION_BACKGROUND_COLOR} !important`;
      default:
        return `${SIDENAV_DEFAULT_BACKGROUND_COLOR} !important`;
    }
  }

  async onLogout() {
    await this.authService.logout();
  }

  async openSettings() {
    this.dialog.open(AppSettingsDialogComponent, {
      minWidth: 380,
      minHeight: 120,
    });
  }

  async toggleMenu() {
    this.menuIsOpen = !this.menuIsOpen;
    localStorage.setItem('menuIsOpen', this.menuIsOpen.toString());
  }
  updateToLatest() {
    location.reload();
  }

  isProduction() {
    return environment.name === 'production';
  }

  ngOnDestroy() {
    this.#onDestroy$.complete();
  }
}
