import { Injectable } from '@angular/core';
import { Observable, interval, of } from 'rxjs';
import { catchError, startWith, exhaustMap, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FrapiService } from './frapi.service';
import { MultiStoreService } from './multi-store.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class FrapiVersionService {
  private CHECK_INTERVAL = 5_000;

  // How long to wait for an http response before failing, and considering FRAPI
  // to be unavailable.
  private CHECK_TIMEOUT = 2_000;
  private FRAPI_CHECK_PATH = `frapi_version.txt`;
  private CHECK_ENABLED = environment.production;
  private CHECK_DISABLED_MESSAGE = 'Local FRAPI v?.?.?';
  public facilityContext: string | null = null;

  constructor(
    private frapiService: FrapiService,
    private multiStoreService: MultiStoreService,
    private loggerService: LoggerService
  ) {}

  intervalGetFrapiVersion(): Observable<string | null> {
    return interval(this.CHECK_INTERVAL).pipe(
      startWith(0),
      exhaustMap(() => this.getVersionOrFail())
    );
  }

  getVersion(facility: string): Observable<string> {
    return this.frapiService.getJson<string>(facility, this.FRAPI_CHECK_PATH);
  }

  getVersionOrFail(): Observable<string | null> {
    const facility = this.multiStoreService.getCurrentFacilityIdentifier();
    if (!this.CHECK_ENABLED) {
      return of(this.CHECK_DISABLED_MESSAGE);
    }
    return this.getVersion(facility).pipe(
      timeout(this.CHECK_TIMEOUT),
      catchError((value) => {
        this.loggerService.debug({
          context: 'FrapiVersionService',
          eventName: 'getVersionOrFail',
          data: { error: value },
        });
        return of(null);
      })
    );
  }
}
