import { Injectable } from '@angular/core';
import {
  LaborJob,
  LaborJobStatusTransition,
  LaborJobType,
} from '@fulfil0518/fulfil-api-libs/laborjob';
import { lastValueFrom } from 'rxjs';
import { CloudApiService } from 'src/app/core/services/cloud-api.service';

interface JobTransition {
  id: number;
  transition: LaborJobStatusTransition;
  timeDeductionInMilliseconds?: number;
}

export interface CompleteLaborJobData {
  timeDeductionInMilliseconds?: number;
  id: number;
}

@Injectable({
  providedIn: 'root',
})
export class LaborJobService {
  constructor(private cloudApiService: CloudApiService) {}

  async createJob(jobType: LaborJobType): Promise<LaborJob> {
    return lastValueFrom(
      this.cloudApiService.post<LaborJob>('laborjob', {
        jobType,
      })
    );
  }

  async cancelJob(jobId: number): Promise<LaborJob> {
    return this.transitionJob({
      id: jobId,
      transition: LaborJobStatusTransition.Cancel,
    });
  }

  async completeJob(data: CompleteLaborJobData): Promise<LaborJob> {
    return this.transitionJob({
      id: data.id,
      transition: LaborJobStatusTransition.Complete,
      timeDeductionInMilliseconds: data.timeDeductionInMilliseconds,
    });
  }

  async startJob(jobId: number): Promise<LaborJob> {
    return this.transitionJob({
      id: jobId,
      transition: LaborJobStatusTransition.Start,
    });
  }

  async transitionJob(jobTransition: JobTransition): Promise<LaborJob> {
    const { id, transition, timeDeductionInMilliseconds } = jobTransition;

    return lastValueFrom(
      this.cloudApiService.post<LaborJob>(
        `laborjob/${id}/transition/${transition}`,
        {
          timeDeduction: timeDeductionInMilliseconds,
        }
      )
    );
  }
}
