import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

export interface LaborJobAFKResult {
  resume: boolean;
}

@Component({
  selector: 'app-labor-job-afkdialog',
  templateUrl: './labor-job-afkdialog.component.html',
  styleUrls: ['./labor-job-afkdialog.component.scss'],
})
export class LaborJobAFKDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<LaborJobAFKDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { minutes: number }
  ) {}

  stop() {
    this.dialogRef.close({ resume: false });
  }

  continue() {
    this.dialogRef.close({ resume: true });
  }
}
