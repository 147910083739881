export const environment = {
  production: true,
  name: 'staging',
  AMAZON_LOGOUT: 'https://beta-iib.auth.us-west-2.amazoncognito.com/logout?client_id=12suql7ks0n3kcr664jqohlnp1',
  API_URL: 'https://fulfil-api-staging.np-fulfil-api.com/', // fulfil-api
  FRAPI_URL: 'https://frapi-staging.np-fulfil-api.com/', // FRAPI
  FRAPI_URL_TEMPLATE: 'https://frapi-staging.np-fulfil-api.com/', // FRAPI
  FDT_API_URL: '/', // fdt
  FDT_API_URL_TMPL: '/',
  KC_BASE_URL: 'https://auth-staging.np-fulfil-api.com/auth',
  ACCOUNT_MANAGMENT_URL:
    'https://auth-staging.np-fulfil-api.com/auth/realms/fulfil/account',
  SIMULATION_NAME: null, // in simulation environment this is provided at deploy time
  ENV_DISPLAY_NAME: 'STAGING',
  SHINOBI_API_KEY: 'aGjsyZBcGQ5m8Y4EJvFGyKojR36cTF',
  PUSHER_CLUSTER: 'us3',
  PUSHER_API_KEY: '8ff5ec22101f7198aca2',
  FACILITY_IDENTIFIER: 'pioneer',
  GCS: {
    'pim-bulk-upload': 'pim-bulk-upload-staging',
  },
  sentry: {
    dsn: 'https://a9feca8221624448a4867611def0250f@o398468.ingest.sentry.io/6633525',
    enabled: true,
    environment: 'staging',
  },
};
