<ng-template #progressSpinnerRef>
  <mat-progress-spinner
    [color]="color"
    [diameter]="diameter"
    [mode]="mode!"
    [strokeWidth]="strokeWidth"
    [value]="value"
  >
  </mat-progress-spinner>
</ng-template>
