<div
  (click)="enlargeImage()"
  class="image-footer"
  [ngStyle]="adjustStylesBasedOnDimensions()"
>
  <img
    [ngStyle]="adjustImageBasedOnDimensions()"
    src="../../../../assets/icons/zoom_out_map.svg"
    alt="zoom out"
  />
</div>
