import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-image-enlarge-footer',
  templateUrl: './image-enlarge-footer.component.html',
  styleUrls: ['./image-enlarge-footer.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class ImageEnlargeFooterComponent {
  @Input() topOffset!: number;
  @Input() footerWidth!: number;
  @Input() rotationAngle = 0;
  @Output() buttonClicked = new EventEmitter<boolean>();
  adjustStylesBasedOnDimensions() {
    // we are positioning this programmatically based on the height of the loaded image minus the size of the footer
    const styles: Partial<CSSStyleDeclaration> = {
      top: `${this.topOffset - 40}px`,
      width: `${this.footerWidth}px`,
    };
    return styles;
  }
  adjustImageBasedOnDimensions() {
    // we are positioning this programmatically based on the height of the loaded image minus the size of the footer
    const styles: Partial<CSSStyleDeclaration> = {
      transform: `rotate(${this.rotationAngle}deg)`,
    };
    return styles;
  }
  enlargeImage() {
    this.buttonClicked.emit(true);
  }
}
