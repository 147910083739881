import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { catchError, Observable, of } from 'rxjs';
import { WarningDialogComponent } from 'src/app/shared/components/warning-dialog/warning-dialog.component';
import { SnackbarWithLinkComponent } from 'src/app/shared/components/snackbar-with-link/snackbar-with-link.component';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { SnackBarLinkItem } from '../types';

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  constructor(private snackBar: MatSnackBar, private dialog: MatDialog) {}

  /**
   * simple usage: notifyService.showToast('Hello');
   * custom usage: notifyService.showToast({ message: 'Hello', duration: 2000 });
   * @param messageOrOptions string | object
   */
  showToast(messageOrOptions: string | { duration?: number; message: string }) {
    const message =
      typeof messageOrOptions === 'string'
        ? messageOrOptions
        : messageOrOptions.message;
    const duration =
      (typeof messageOrOptions === 'object' && messageOrOptions.duration) ||
      2000;
    this.snackBar.open(message, '', {
      duration,
    });
  }

  /**
   * Show toast in the case of error. Displays message and a red Ok text that user has to click to make toast disappear
   * @param message string
   */
  showToastWithConfirm(message: string) {
    this.snackBar.open(message, 'Ok');
  }

  /**
   * Show toast along with a link in the case of error. Displays message and a red Ok text that user has to click to make toast disappear
   * @param message string
   * @param link SnackBarLinkItem
   */
  showToastWithLinkAndConfirm(message: string, link: SnackBarLinkItem) {
    this.snackBar.openFromComponent(SnackbarWithLinkComponent, {
      data: { message: message, link: link, confirmText: 'Ok' },
    });
  }

  /**
   * Show a confirm dialog. Resolves with boolean indicating whether user confirmed or not
   * @example const confirmed = await this.notifyService.confirm({message: 'Are you sure you want to delete?', cta: 'Delete'});
   * @param options
   * @return Promise<boolean>
   */
  confirm({
    message,
    cta,
    header,
    cancelCta = 'Cancel',
  }: {
    message: string;
    cta: string;
    cancelCta?: string;
    header?: string;
  }): Promise<boolean> {
    return new Promise((resolve) => {
      const data: ConfirmDialogData = {
        message,
        cta,
        cancelCta,
        header,
      };
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data,
      });
      dialogRef.afterClosed().subscribe((confirmed) => {
        resolve(confirmed || false);
      });
    });
  }

  /**
   * Show a warning dialog. Resolves with confirmed
   * @example const confirmed = await this.notifyService.confirm({message: 'Are you sure you want to delete?', cta: 'Okay'});
   * @param options
   * @return Promise<boolean>
   */
  warning({
    title,
    message,
    cta,
  }: {
    title?: string;
    message: string;
    cta: string;
    cancelCta?: string;
  }): Promise<boolean> {
    return new Promise((resolve) => {
      const dialogRef = this.dialog.open(WarningDialogComponent, {
        data: { title, message, cta },
      });
      dialogRef.afterClosed().subscribe((confirmed) => {
        resolve(confirmed || false);
      });
    });
  }

  /**
   * An extension of RxJS's `catchError()`.
   * It shows a toast with the error message, and emits the provided fallback value.
   *
   * @example
   * ```
   * this.machinesService.getGenericStatusOptions(id)
   *   .pipe(
   *     this.notifyService.catchError(
   *       'Error fetching generic status options:',
   *       []
   *     )
   *   )
   * ```
   * This will show a toast with the message "Error fetching generic status options: \<api error message\>".
   *
   * @param {string} summaryMessage - The summary message to show in a toast. Will appear before the source Observable's error message.
   * @param {T} fallbackValue - The fallback value to emit from the Observable.
   */
  catchError<Type, FallbackType>(
    summaryMessage: string,
    fallbackValue: FallbackType
  ): (source$: Observable<Type>) => Observable<Type | FallbackType> {
    return (source$) =>
      source$.pipe(
        catchError((error) => {
          const messageWithError = `${summaryMessage}: ${
            error.message ?? error
          }`;
          if (error.traceId) {
            this.showToastWithLinkAndConfirm(messageWithError, {
              text: `For support, post error in: `,
              urlText: '#cloud-team',
              copyText: `${error.source} trace id: ${error.traceId} for error: ${error.message}, at ${window.location.href}`,
              url: 'https://fulfilsolutions.slack.com/archives/C017Z8UG7QC',
            });
          } else {
            this.showToastWithConfirm(messageWithError);
          }
          return of(fallbackValue);
        })
      );
  }
}
