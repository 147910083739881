import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { GlobalJobWidgetComponent } from './global-job-widget.component';
import { GlobalLaborJobButtonModule } from '../global-labor-job-button/global-labor-job-button.module';
import { WidgetMoverDirective } from './widget-mover.directive';

@NgModule({
  declarations: [GlobalJobWidgetComponent, WidgetMoverDirective],
  imports: [
    CommonModule,
    MatBadgeModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    GlobalLaborJobButtonModule,
  ],
  exports: [GlobalJobWidgetComponent, WidgetMoverDirective],
})
export class GlobalJobWidgetModule {}
