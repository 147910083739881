/* eslint-disable default-case */
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MediaMatcher } from '@angular/cdk/layout';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { environment } from 'src/environments/environment';
import { MultiStoreService } from 'src/app/core/services/multi-store.service';
import { AppVersionService } from 'src/app/core/services/app-version.service';

/* this component started as a copy paste of https://stackblitz.com/angular/eaaaydymgpdx?file=src%2Fapp%2Fsidenav-responsive-example.html
   this example is linked to from the Angular Material docs here https://material.angular.io/components/sidenav/overview#creating-a-responsive-layout-for-mobile--desktop
*/

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  envDisplayName = environment.SIMULATION_NAME || environment.ENV_DISPLAY_NAME;
  hasFacilityContext = false;
  hasVendorStoreContext = false;
  isOnline = navigator.onLine;
  newVersion = 'latest';
  menuIsOpen = true;
  mobileQuery: MediaQueryList;

  private subscriptions: Subscription[] = [];
  private _mobileQueryListener: () => void;

  constructor(
    media: MediaMatcher,
    changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    public multiStoreService: MultiStoreService,
    private appVersionService: AppVersionService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    if (this.mobileQuery.matches) {
      this.menuIsOpen = false;
    }
  }

  async ngOnInit() {
    window.addEventListener('online', () => {
      this.isOnline = true;
    });
    window.addEventListener('offline', () => {
      this.isOnline = false;
    });

    this.subscriptions.push(
      this.route.queryParams.subscribe(({ facility, vendorstore }) => {
        this.hasFacilityContext = !!(facility || vendorstore);
        this.hasVendorStoreContext = !!vendorstore;
      })
    );

    // Set page title while page change events
    this.subscriptions.push(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.route.snapshot),
          map((route) => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          })
        )
        .subscribe((route: ActivatedRouteSnapshot) => {
          if (route.data) {
            this.setPageTitle(
              route.data.pageTitle ||
                route.data.title ||
                route.data.pageMetadata.title
            );
          }
        })
    );

    // Set page title while page load event for first time only
    this.subscriptions.push(
      this.route.url
        .pipe(
          map(() => this.route.snapshot),
          map((route) => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          })
        )
        .subscribe((route: ActivatedRouteSnapshot) => {
          if (route.data) {
            this.setPageTitle(
              route.data.pageTitle ||
                route.data.title ||
                route.data.pageMetadata.title
            );
          }
        })
    );
    this.subscriptions.push(
      this.appVersionService.isNewVersionDetected$.subscribe((newVersion) => {
        this.newVersion = newVersion;
      })
    );
  }

  setPageTitle(title?: string): void {
    if (title) {
      const pageTitle = title || 'Fulfil Dashboard';
      this.titleService.setTitle(pageTitle);
    }
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription?.unsubscribe();
    }
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  isProduction() {
    return environment.name === 'production';
  }

  onClickedLink() {
    if (this.mobileQuery.matches) {
      this.menuIsOpen = false;
    }
  }
}
