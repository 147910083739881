<div>
  <h1 mat-dialog-title>
    {{ data.header || 'Are you sure?' }}
  </h1>
  <div mat-dialog-content class="content">
    <p class="confirm-message" [innerHTML]="message"></p>
    <br />
  </div>
  <div mat-dialog-actions align="end">
    <button
      [attr.data-testid]="'confirm-dialog--cancel'"
      mat-button
      (click)="dialogRef.close()"
    >
      {{ cancelCta }}
    </button>
    <button
      mat-raised-button
      color="primary"
      [attr.data-testid]="'confirm-dialog--confirm'"
      (click)="dialogRef.close(true)"
      cdkFocusInitial
    >
      {{ cta }}
    </button>
  </div>
</div>
