import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { AuthService } from 'src/app/core/services/auth.service';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacySlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { NotifyService } from 'src/app/core/services/notify.service';
import {
  enterKeydownDebuggerEnabled,
  setEnterKeydownDebugger,
} from './app-settings-dialog';

export type AppSettingsDialogData = {
  settingsChanged: boolean;
};

export const LOGIN_REDIRECT_PATH =
  'app-settings-dialog.component_login_redirect_path';

@Component({
  selector: 'app-settings-dialog',
  templateUrl: './app-settings-dialog.component.html',
  styleUrls: ['./app-settings-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    FormsModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatLegacySlideToggleModule,
    ReactiveFormsModule,
  ],
})
export class AppSettingsDialogComponent implements OnInit {
  defaultRedirect = '';
  currentPage = '';

  autoLogoutEnabled = false;
  autoLogoutTimeMinutes = new FormControl<number | null>(null);
  enterKeydownDebugger = new FormControl<boolean>(false);

  constructor(
    public dialogRef: MatDialogRef<AppSettingsDialogComponent>,
    private authService: AuthService,
    private notifyService: NotifyService
  ) {}

  async ngOnInit() {
    // init redirect
    const loginRedirectPath = localStorage.getItem(LOGIN_REDIRECT_PATH);
    this.currentPage = window.location.pathname + window.location.search;
    this.defaultRedirect = loginRedirectPath || '';

    // init auto logout
    const idleTimeSeconds = this.authService.getIdleTimeSeconds();
    if (idleTimeSeconds === null) {
      this.autoLogoutEnabled = false;
      this.autoLogoutTimeMinutes.patchValue(null);
    } else {
      this.autoLogoutEnabled = true;
      this.autoLogoutTimeMinutes.patchValue(idleTimeSeconds / 60);
    }

    this.enterKeydownDebugger.patchValue(enterKeydownDebuggerEnabled());
  }

  handleSaveRedirectClick() {
    this.defaultRedirect = window.location.pathname + window.location.search;
    localStorage.setItem(LOGIN_REDIRECT_PATH, this.defaultRedirect);
    this.notifyService.showToast('Saved login redirect URL');
  }

  async handleSaveAutoLogoutClick() {
    if (this.autoLogoutEnabled) {
      this.authService.setIdleTimeSeconds(
        this.autoLogoutTimeMinutes.value! * 60
      );
    } else {
      this.authService.setIdleTimeSeconds(null);
    }
    this.notifyService.showToast('Saved auto logout');
  }

  handleSaveEnterKeydownEnabled(checked: boolean) {
    setEnterKeydownDebugger(checked);
    this.notifyService.showToast(
      checked
        ? 'Enabled Enter Keydown Debugger'
        : 'Disabled Enter Keydown Debugger'
    );
  }

  handleAutoLogoutEnabledChange(enabled: boolean) {
    if (!enabled) {
      this.autoLogoutTimeMinutes.patchValue(null);
    }
  }
}
