import { Injectable } from '@angular/core';
import { FrapiVersionService } from './frapi-version.service';
import { NotifyService } from './notify.service';

@Injectable({
  providedIn: 'root',
})
export class FrapiGuard {
  constructor(
    private frapiVersionService: FrapiVersionService,
    private notifyService: NotifyService
  ) {}

  canActivate() {
    this.frapiVersionService.getVersionOrFail().subscribe((value) => {
      if (!value) {
        this.notifyService.showToastWithConfirm(
          `Unable to reach FRAPI. Are you connected to OpenVPN?`
        );
      }
      return true;
    });
  }
}
