import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { WebVersionPipe } from '../../pipes/web-version.pipe';
import { HeaderModule } from '../header/header.module';
import { GlobalJobWidgetModule } from '../global-job-widget/global-job-widget.module';
import { LayoutComponent } from './layout.component';
import { NavListModule } from './nav-list/nav-list.module';

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,
    HeaderModule,
    MatSidenavModule,
    MatIconModule,
    NavListModule,
    MatButtonModule,
    MatTooltipModule,
    RouterModule,
    GlobalJobWidgetModule,
    WebVersionPipe,
  ],
  exports: [LayoutComponent],
})
export class LayoutModule {}
