<button
  *ngIf="data.link.copyText"
  mat-icon-button
  color="primary"
  (click)="copyText(data.link.copyText)"
>
  <mat-icon>content_copy</mat-icon>
</button>
{{ data.message }}

{{ data.link.text }}
<a href="{{ data.link.url }}" target="_blank" rel="noopener">
  {{ data.link.urlText }}
</a>
<button mat-button color="primary" (click)="dismiss()">
  {{ data.confirmText }}
</button>
