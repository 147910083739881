<div>
  <span> New version of the web app is available </span>
  <span>
    <button
      [attr.data-testid]="'update-version-button'"
      mat-stroked-button
      color="primary"
      (click)="updateToLatest()"
    >
      Update to {{ version | webVersion }}
    </button>
    <button mat-icon-button aria-label="Close" (click)="snackBarRef.dismiss()">
      <mat-icon>close</mat-icon>
    </button>
  </span>
</div>
