import {
  Component,
  OnInit,
  Input,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
  DoCheck,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import {
  MatLegacyProgressSpinnerModule as MatProgressSpinnerModule,
  LegacyProgressSpinnerMode as ProgressSpinnerMode,
} from '@angular/material/legacy-progress-spinner';
import {
  OverlayRef,
  PositionStrategy,
  Overlay,
  OverlayConfig,
  OverlayModule,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';

export type AppOverlayConfig = OverlayConfig;

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule, OverlayModule],
})
export class LoaderComponent implements OnInit, DoCheck {
  @Input() color?: ThemePalette;
  @Input() diameter = 100;
  @Input() mode?: ProgressSpinnerMode;
  @Input() strokeWidth?: number;
  @Input() value?: number;
  @Input() backdropEnabled = true;
  @Input() positionGloballyCenter = true;
  @Input() displayProgressSpinner = false;

  @ViewChild('progressSpinnerRef', { static: true })
  // @ts-expect-error ts(2564)
  private progressSpinnerRef: TemplateRef<any>;
  // @ts-expect-error ts(2564)
  private progressSpinnerOverlayConfig: AppOverlayConfig;
  // @ts-expect-error ts(2564)
  private overlayRef: OverlayRef;

  constructor(private vcRef: ViewContainerRef, private overlay: Overlay) {}

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    // Config for Overlay Service
    this.progressSpinnerOverlayConfig = {
      hasBackdrop: this.backdropEnabled,
    };
    if (this.positionGloballyCenter) {
      const positionStrategy = 'positionStrategy';
      this.progressSpinnerOverlayConfig[positionStrategy] =
        this.setPositionGloballyCenter();
    }
    // Create Overlay for progress spinner
    this.overlayRef = this.createOverlay(this.progressSpinnerOverlayConfig);
  }
  /**
   * ngDoCheck
   */
  // tslint:disable-next-line: use-life-cycle-interface
  ngDoCheck(): void {
    // Based on status of displayProgressSpinner attach/detach overlay to progress spinner template
    if (this.displayProgressSpinner && !this.overlayRef.hasAttached()) {
      this.attachTemplatePortal(
        this.overlayRef,
        this.progressSpinnerRef,
        this.vcRef
      );
    } else if (!this.displayProgressSpinner && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }
  /**
   * Create overlay
   * @param config AppOverlayConfig
   */
  createOverlay(config: AppOverlayConfig): OverlayRef {
    return this.overlay.create(config);
  }

  /**
   * Inject html tempate
   * @param overlayRef OverlayRef
   * @param templateRef TemplateRef<any>
   * @param vcRef ViewContainerRef
   */
  attachTemplatePortal(
    overlayRef: OverlayRef,
    templateRef: TemplateRef<any>,
    vcRef: ViewContainerRef
  ): void {
    const templatePortal = new TemplatePortal(templateRef, vcRef);
    overlayRef.attach(templatePortal);
  }

  /**
   * Set overlay in center
   */
  setPositionGloballyCenter(): PositionStrategy {
    return this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();
  }
}
