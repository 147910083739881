import {
  Injectable,
  OnDestroy,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { EMPTY, of } from 'rxjs';
import {
  expand,
  filter,
  map,
  takeUntil,
  distinctUntilChanged,
} from 'rxjs/operators';
import { OnDestroySubject } from 'src/app/shared/observables/on-destroy-subject';

@Injectable({
  providedIn: 'root',
})
export class WorkstationStyleService implements OnDestroy {
  private renderer: Renderer2;
  private onDestroy$ = new OnDestroySubject();
  private readonly CLASS_NAME = 'fulfil-workstation';

  constructor(
    private router: Router,
    rendererFactory: RendererFactory2,
    private route: ActivatedRoute
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route.snapshot),
        expand((routeSnapshot) =>
          routeSnapshot.firstChild ? of(routeSnapshot.firstChild) : EMPTY
        ),
        map((r) => r.data.workstation),
        distinctUntilChanged(),
        takeUntil(this.onDestroy$)
      )
      .subscribe((workstation) => {
        if (workstation) {
          this.renderer.addClass(document.body, this.CLASS_NAME);
          this.renderer.setStyle(document.documentElement, 'font-size', '28px');
        } else {
          this.renderer.removeClass(document.body, this.CLASS_NAME);
          this.renderer.removeStyle(document.documentElement, 'font-size');
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.complete();
  }
}
