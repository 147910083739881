<h1 mat-dialog-title>Are you still there?</h1>

<div mat-dialog-content>
  It seems the session has been inactive for {{ data.minutes }} minutes. Please
  continue the session or end it.
</div>

<div mat-dialog-actions align="end">
  <button mat-button (click)="continue()">Continue Session</button>
  <button mat-button (click)="stop()" color="warn">End Session</button>
</div>
