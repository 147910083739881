<mat-card *ngIf="show | async">
  <div [class.hidden]="!collapsed" class="collpased-container">
    <div
      [matBadge]="inProgressJobCount | async"
      matBadgeOverlap="false"
      matBadgeColor="warn"
      class="count-text"
      *ngIf="(inProgressJobCount | async)! > 0"
    >
      Started
    </div>
    <div
      [matBadge]="notStartedJobCount | async"
      matBadgeOverlap="false"
      matBadgeColor="primary"
      class="count-text"
      *ngIf="(notStartedJobCount | async)! > 0"
    >
      Not Started
    </div>
    <button
      mat-icon-button
      (click)="collapsed = false"
      class="expand-icon-button"
    >
      <mat-icon>expand_less</mat-icon>
    </button>
  </div>

  <div [class.hidden]="collapsed">
    <mat-card-title>Session Tracker</mat-card-title>
    <div class="collapse-button-wrapper">
      <button
        mat-icon-button
        class="collapse-icon-button"
        (click)="collapsed = true"
      >
        <mat-icon>expand_more</mat-icon>
      </button>
    </div>
    <ng-container *ngFor="let jobType of ALL_JOB_TYPES">
      <div class="job" *ngIf="(jobMap | async)![jobType].show">
        <app-global-labor-job-button
          [laborJobType]="jobType"
        ></app-global-labor-job-button>
      </div>
    </ng-container>
  </div>
</mat-card>
