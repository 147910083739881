import { Injectable } from '@angular/core';
import { LaborJobEvent } from '@fulfil0518/fulfil-api-libs/laborjob';
import { lastValueFrom } from 'rxjs';
import { CloudApiService } from 'src/app/core/services/cloud-api.service';

@Injectable({
  providedIn: 'root',
})
export class LaborJobEventService {
  constructor(private cloudApiService: CloudApiService) {}

  async createEvent(
    laborJobId: number,
    payload: string
  ): Promise<LaborJobEvent> {
    if (laborJobId === null || laborJobId === undefined) {
      throw new Error('laborJobId is required.');
    }

    return lastValueFrom(
      this.cloudApiService.post('laborjobevent', { laborJobId, payload })
    );
  }
}
