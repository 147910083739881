import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FactoryOrderParams } from '@frapi/orders';
import { FactorOrderResponse } from '@frapi/orders/factory-orders';
import { CartItem, Order } from '@fulfil0518/fulfil-api-libs/orders';
import { Refund } from '@fulfil0518/fulfil-api-libs/refunds';
import { OpsShops } from '@fulfil0518/fulfil-api-libs/stockroom/ops-shops';
import { isNil, omitBy } from 'lodash';
import { Observable, lastValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FrapiService } from 'src/app/core/services/frapi.service';
import { OrderFilters } from 'src/app/shared/components/order-filters/order-filters.component';
import { FrapiResponse } from '../types';
import { CloudApiService } from './cloud-api.service';
import { MultiStoreService } from './multi-store.service';

export interface OrderDetails {
  order: Order;
  items: CartItem[];
  refunds?: Refund[];
}

interface OrderReceiptPrintJob {
  createdAt: number;
  updatedAt: number;
  status: string;
  id: number;
  printer_id: number;
  payload: string;
  payload_type: string;
}

export interface OrderReceiptPrintJobStatus {
  success: boolean;
  message: string;
}

@Injectable()
export class OrderService {
  LONG_RUNNING_ORDER_MINUTES = 35;

  constructor(
    private cloudApi: CloudApiService,
    private frapiService: FrapiService,
    private multiStoreService: MultiStoreService,
    private cloudApiService: CloudApiService
  ) {}

  getOpsShops(orderId: number): Promise<OpsShops[]> {
    return lastValueFrom(
      this.cloudApiService.get<OpsShops[]>(`ops-shops?order_id=${orderId}`)
    );
  }

  async getOrderDetails(id: number): Promise<OrderDetails> {
    const url = `order/order-details?id=${id}`;

    return lastValueFrom(this.cloudApi.get(url));
  }

  async abandonOrder(id: number) {
    const url = `order/${id}`;

    return lastValueFrom(this.cloudApi.patch(url, { status: 'abandoned' }));
  }

  async getOrdersCount(params: OrderFilters): Promise<number> {
    // @ts-expect-error ts(2322)
    const _params = new HttpParams({ fromObject: omitBy(params, isNil) });
    const url = `order/count?${_params.toString()}`;
    return (await lastValueFrom(this.cloudApi.get<{ count: number }>(url)))
      .count;
  }

  async getOrdersAtRisk(): Promise<Order[]> {
    return lastValueFrom(
      this.cloudApiService.get<Order[]>(
        `order/at-risk?facilityIdentifier=${this.multiStoreService.getCurrentFacilityIdentifier()}`
      )
    );
  }

  async getSpecificFactoryOrders(
    orders: any[],
    filteredResponse = false
  ): Promise<any> {
    const orderIds: number[] = [];
    orders.forEach((order) => {
      orderIds.push(order.id);
    });
    const url = `orders?limit=${
      orders.length
    }&typedFilters=[{"filter":{"fieldString":"CloudOrderID","type":"In","fieldValue":${JSON.stringify(
      orderIds
    )}
        }}]&filterOutput=${filteredResponse}&includeCompleted=true&includeBags=true&includeDispenses=true`;
    const result: any = await lastValueFrom(
      this.frapiService.get(
        this.multiStoreService.getCurrentFacilityIdentifier(),
        url
      )
    );
    return result;
  }

  async getFactoryOrderStatusOptions(): Promise<
    { value: number; displayText: string }[]
  > {
    const { result } = await lastValueFrom(
      this.frapiService.get<any>(
        this.multiStoreService.getCurrentFacilityIdentifier(),
        'orders/order-status-options'
      )
    );

    return result;
  }

  /**
   * Get list of orders with the given pagination parameters.
   * @param page number of orders to skip
   * @param limit number of orders to retrieve
   */
  async getCloudOrders(
    params: OrderFilters & { page?: number; limit?: number }
  ): Promise<Order[]> {
    let _params: HttpParams;
    if (params.order_id) {
      _params = new HttpParams({
        fromObject: { order_id: params.order_id, page: 0, limit: 1 },
      });
    } else {
      // @ts-expect-error ts(2322)
      _params = new HttpParams({ fromObject: omitBy(params, isNil) });
    }
    const result: any = await lastValueFrom(
      this.cloudApiService.get<Order[]>(`order/latest?${_params.toString()}`)
    );

    return result.orders;
  }

  getFactoryOrders(
    factoryOrderParams: FactoryOrderParams
  ): Observable<FactorOrderResponse> {
    let params = '';
    // .get() does not take in a params objects and only takes in a URL and headers
    // we have to manually append query params to the route
    Object.entries(factoryOrderParams).forEach((value) => {
      params += `${value[0]}=${value[1]}&`;
    });
    const url = `orders/order-details?${params}`;
    return this.frapiService
      .get<FrapiResponse<FactorOrderResponse>>(
        this.multiStoreService.getCurrentFacilityIdentifier(),
        url
      )
      .pipe(map((response) => response.result || []));
  }

  async getOrderById(orderId: number): Promise<Order | null> {
    const result = await lastValueFrom(
      this.cloudApiService.get<{ orders: Order[] }>(
        `order/latest?orderId=${orderId}&skip=0&limit=1`
      )
    );
    return result.orders[0] || null;
  }

  async cancelDelivery(orderId: number): Promise<any> {
    const url = `delivery/cancel/${orderId}`;
    const response = await lastValueFrom(this.cloudApi.put(url, {}));
    return response;
  }

  async recreateDelivery(orderId: number): Promise<any> {
    const url = `delivery/recreate/${orderId}`;
    const response = await lastValueFrom(this.cloudApi.patch(url, {}));
    return response;
  }

  async getDelivery(orderId: number): Promise<any> {
    const url = `delivery/${orderId}`;
    const response = await lastValueFrom(this.cloudApi.get(url));
    return response;
  }

  async enqueueOrderReceiptPrintJob(
    bagId: number
  ): Promise<OrderReceiptPrintJobStatus> {
    const printerIdentifier = 'third';
    const facilityId = this.multiStoreService.getCurrentFacilityIdentifier();
    const payload = {};
    let _params: HttpParams;
    if (bagId) {
      _params = new HttpParams({
        fromObject: {
          printer_identifier: printerIdentifier,
          facility_identifier: facilityId,
          bag_id: bagId,
        },
      });
    } else {
      return {
        success: false,
        message: "Could not determine the bag's cloud database id.",
      };
    }
    const response = await lastValueFrom(
      this.cloudApiService
        .post<OrderReceiptPrintJob>(
          `printer/job/order-receipt?${_params.toString()}`,
          payload
        )
        .pipe(
          map((resp: OrderReceiptPrintJob) => ({
            success: true,
            message: resp.status,
          })),
          catchError((e) => {
            return of({
              success: false,
              message: e.message || 'Print job could not be queued.',
            });
          })
        )
    );
    return response;
  }

  async getOrderEvents(orderId: number): Promise<{
    orderEvents: any[];
    deliveryEvents: any[];
  }> {
    const url = `order/cloudevents/${orderId}`;
    const response = await lastValueFrom(
      this.cloudApiService.get<{
        orderEvents: any[];
        deliveryEvents: any[];
      }>(url)
    );
    return response;
  }

  async cancelOrder(orderId: number): Promise<Order> {
    const url = `order/cancel?id=${orderId}`;
    const response = await lastValueFrom(
      this.cloudApiService.post<Order>(url, {})
    );
    return response;
  }

  fetchLongRunningOrders(): Observable<number[]> {
    const params = new HttpParams({
      fromObject: {
        filterOutput: false,
        filteredCols: JSON.stringify({
          'Fulfil.Libs.Inventory.Orders.Order': ['CloudOrderID'],
        }),
        includeCompleted: false,
        includeBags: false,
        includeDispenses: false,
        typedFilters: JSON.stringify([
          {
            filter: {
              fieldString: 'CloudOrderID',
              type: 'Ne',
              fieldValue: 0,
            },
          },
          {
            filter: {
              fieldString: 'OrderStatus',
              type: 'Eq',
              fieldValue: 'Active',
            },
          },
          {
            filter: {
              fieldString: 'StartTime',
              type: 'Lt',
              fieldValue: new Date(
                new Date().setMinutes(
                  new Date().getMinutes() - this.LONG_RUNNING_ORDER_MINUTES
                )
              ).toISOString(),
            },
          },
        ]),
      },
    });
    return this.frapiService
      .get<FrapiResponse<{ CloudOrderID: number }[]>>(
        this.multiStoreService.getCurrentFacilityIdentifier(),
        `orders?${params.toString()}`
      )
      .pipe(map(({ result }) => result.map((order) => order.CloudOrderID)));
  }

  forcePickUpOrder(orderId: string): Observable<string> {
    const url = `orders/${orderId}/force-pickup`;
    return this.frapiService
      .patch<string>(
        this.multiStoreService.getCurrentFacilityIdentifier(),
        url,
        {}
      )
      .pipe(map((response) => response || ''));
  }
}
