import { Component, Inject, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarModule,
} from '@angular/material/snack-bar';
import { VersionResponse } from 'src/app/core/types/version';
import { WebVersionPipe } from '../../pipes/web-version.pipe';

@Component({
  standalone: true,
  selector: 'app-version',
  templateUrl: './app-version.component.html',
  styleUrls: ['./app-version.component.scss'],
  imports: [
    MatLegacyButtonModule,
    MatIconModule,
    MatSnackBarModule,
    WebVersionPipe,
  ],
})
export class AppVersionComponent implements OnInit {
  version = 'latest';
  constructor(
    public snackBarRef: MatSnackBarRef<AppVersionComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: VersionResponse
  ) {
    this.version = data.version;
  }

  ngOnInit(): void {}

  updateToLatest() {
    location.reload();
  }
}
