<div class="app-container">
  <div class="app-side-nav">
    <app-nav-list (clickedLink)="onClickedLink()"></app-nav-list>
  </div>
  <div class="app-main-content">
    <app-header-outlet></app-header-outlet>
    <router-outlet></router-outlet>
  </div>
  <div *ngIf="!isOnline" class="offline-notification">
    <mat-icon>wifi_off</mat-icon>
    <p>Your browser is offline</p>
  </div>
  <app-global-job-widget appWidgetMover></app-global-job-widget>
</div>
