import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  loader = new Subject<{ isLoaderVisible: boolean }>();

  constructor() {}
  /**
   * Start Loader
   */
  startLoader(): void {
    this.loader.next({ isLoaderVisible: true });
  }
  /**
   * Stop Loader
   */
  stopLoader(): void {
    this.loader.next({ isLoaderVisible: false });
  }
}
