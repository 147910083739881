import { Pipe, PipeTransform } from '@angular/core';

const SEMVER_REGEX = /^v(\d+)\.(\d+)\.(\d+)$/;

@Pipe({
  name: 'webVersion',
  standalone: true,
})
export class WebVersionPipe implements PipeTransform {
  transform(version: string): string {
    // In simulation, the version is a sha. Shorten the sha.
    return SEMVER_REGEX.test(version) ? version : version.slice(0, 7);
  }
}
