import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { GlobalLaborJobButtonComponent } from './global-labor-job-button.component';

@NgModule({
  declarations: [GlobalLaborJobButtonComponent],
  imports: [CommonModule, MatButtonModule],
  exports: [GlobalLaborJobButtonComponent],
})
export class GlobalLaborJobButtonModule {}
