import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  LaborJob,
  LaborJobStatus,
  LaborJobType,
} from '@fulfil0518/fulfil-api-libs/laborjob';
import {
  combineLatest,
  combineLatestWith,
  EMPTY,
  expand,
  filter,
  map,
  Observable,
  of,
  startWith,
} from 'rxjs';
import { GlobalLaborJobService } from '../../services/global-labor-job.service';

const ALL_JOB_TYPES: LaborJobType[] = [
  LaborJobType.Receiving,
  LaborJobType.Shopping,
  LaborJobType.Induction,
];

@Component({
  selector: 'app-global-job-widget',
  templateUrl: './global-job-widget.component.html',
  styleUrls: ['./global-job-widget.component.scss'],
})
export class GlobalJobWidgetComponent {
  collapsed = false;
  inProgressJobCount: Observable<number>;
  show: Observable<boolean> = of(false);
  jobMap: Observable<
    Record<LaborJobType, { job: LaborJob | null; show: boolean }>
  >;
  notStartedJobCount: Observable<number>;

  readonly ALL_JOB_TYPES = ALL_JOB_TYPES;

  private routerEvent: Observable<unknown> = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    // @ts-expect-error ts(7053)
    startWith(this.router.events[0])
  );

  private routerData: Observable<{
    jobTypes: LaborJobType[];
  }> = combineLatest([this.route.url, this.routerEvent]).pipe(
    map(() => this.route.snapshot),
    expand((route) => (route.firstChild ? of(route.firstChild) : EMPTY)),
    map((route) => ({
      jobTypes: route.data.laborJobTypes,
    })),
    startWith({
      jobTypes: [],
    })
  );

  constructor(
    private globalLaborJobService: GlobalLaborJobService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.jobMap = this.globalLaborJobService.jobs.pipe(
      combineLatestWith(this.routerData),
      map(([serviceJobsMap, routerData]) => {
        const jobMap = {};
        for (const jobType of ALL_JOB_TYPES) {
          const job = serviceJobsMap.get(jobType) || null;
          // @ts-expect-error ts(7053)
          jobMap[jobType] = {
            job,
            show:
              job?.status === LaborJobStatus.InProgress ||
              routerData?.jobTypes?.includes(jobType),
          };
        }
        return jobMap as Record<
          LaborJobType,
          { job: LaborJob | null; show: boolean }
        >;
      })
    );
    this.show = this.jobMap.pipe(
      map((showJobMap) => Object.values(showJobMap).some((value) => value.show))
    );
    this.inProgressJobCount = this.countByStatus(
      (status) => status === LaborJobStatus.InProgress
    );
    this.notStartedJobCount = this.countByStatus(
      (status) => status !== LaborJobStatus.InProgress
    );
  }

  private countByStatus(
    fn: (status?: LaborJobStatus) => boolean
  ): Observable<number> {
    return this.jobMap.pipe(
      map((showJobMap) =>
        Object.entries(showJobMap).reduce((runningTotal, showJobMapEntry) => {
          const [, value] = showJobMapEntry;
          if (!value.show) {
            return runningTotal;
          }
          if (fn(value.job?.status as unknown as LaborJobStatus)) {
            return runningTotal + 1;
          }
          return runningTotal;
        }, 0)
      )
    );
  }
}
