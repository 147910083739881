import { Injectable } from '@angular/core';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { HttpClient } from '@angular/common/http';
import { isNil } from 'lodash';
import {
  catchError,
  exhaustMap,
  filter,
  interval,
  map,
  Observable,
  of,
  share,
  tap,
} from 'rxjs';
import versionFile from 'src/assets/version.json';
import { AppVersionComponent } from 'src/app/shared/components/app-version/app-version.component';
import { VERSION_CHECK_INTERVAL_MS, VersionResponse } from '../types/version';

@Injectable({
  providedIn: 'root',
})
export class AppVersionService {
  didShowSnackbar = false;
  localVersion = '';
  isNewVersionDetected$: Observable<string>;

  constructor(private http: HttpClient, snackBar: MatLegacySnackBar) {
    this.localVersion = versionFile.version;

    this.isNewVersionDetected$ = interval(VERSION_CHECK_INTERVAL_MS).pipe(
      tap(() => console.log('Checking for app update...')),
      exhaustMap(() =>
        this.http
          .get<VersionResponse>('/assets/version.json', {
            headers: { responseType: 'json' },
          })
          .pipe(
            catchError((error) => {
              console.error(
                'Failed to check for updates:',
                error.message ?? error
              );
              return of({ version: '' });
            })
          )
      ),
      filter((response): response is NonNullable<VersionResponse> => {
        return (
          !isNil(response) &&
          response.version.length > 0 &&
          this.localVersion !== response.version
        );
      }),
      map(({ version }) => version),
      /*
        this.isNewVersionDetected$ is subscribed to within this service,
        and is expected to be subscribed to by client classes,
        so share is added here to prevent duplicate executions
        from within the service.
      */
      share()
    );

    this.isNewVersionDetected$
      .pipe(filter((version) => version.length > 0 && !this.didShowSnackbar))
      .subscribe((version) => {
        snackBar.openFromComponent(AppVersionComponent, {
          data: { version },
        });
        this.didShowSnackbar = true;
      });
  }
}
