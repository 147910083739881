export enum ApiErrorStatusCode {
  'BAD_REQUEST' = 400,
  'FORBIDDEN' = 403,
  'NOT_FOUND' = 404,
  'CONFLICT' = 409,
  'SERVICE_UNAVAILABLE' = 503,
}

export type APIErrorSource = 'FRAPI' | 'Cloud API' | undefined;

export class APIError extends Error {
  status: ApiErrorStatusCode;
  handledError: Error;
  constructor(
    message: string,
    status: number,
    handledError: Error,
    public source: APIErrorSource,
    public traceId: string | undefined
  ) {
    super(message);
    this.status = status;
    this.handledError = handledError;
  }
}
