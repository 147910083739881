import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';
import { LaborJobType } from '@fulfil0518/fulfil-api-libs/laborjob';
import { Permission } from '@fulfil0518/fulfil-permissions/core';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { AuthGuard } from './core/services/auth.guard';
import { FrapiGuard } from './core/services/frapi.guard';
import { Claim } from './core/services/permission-schema';
import { CustomPreloadingStrategy } from './core/strategy/custom-preloading';

// claims will differ as we migrate to the permissions library
// todo: after fully replacing hardcoded claims, update this type
export type RouteClaims = {
  claim: Claim | Permission;
  claimValue: boolean | string;
}[];

export interface PageRoute extends Route {
  data: {
    preload?: boolean;
    delay?: boolean;
    delayTime?: number;
    claims?: RouteClaims;
    laborJobTypes?: LaborJobType[];
    pageMetadata: {
      showOnHomepage: boolean;
      customPath?: string;
      title: string;
      icon: string;
      img?: string | null;
    };
  };
}

// the order of this array is the same order that the tiles will appear on the home page
export const pageRoutes: PageRoute[] = [
  {
    path: '',
    loadComponent: () =>
      import('./modules/home/home.component').then(
        (home) => home.HomeComponent
      ),
    data: {
      preload: true,
      delay: true,
      delayTime: 3000,
      pageMetadata: {
        title: 'Home',
        icon: 'home',
        img: null,
        showOnHomepage: false,
      },
    },
  },
  {
    path: 'pre-and-post-flight',
    loadChildren: () =>
      import('./modules/pre-and-post-flight/pre-and-post-flight.module').then(
        (m) => m.PreAndPostFlightModule
      ),
    data: {
      claims: [{ claim: Claim.allowReadNodes, claimValue: true }],
      pageMetadata: {
        title: 'Pre and Post Flight',
        icon: 'power_settings_new',
        img: 'preflight.png',
        showOnHomepage: true,
      },
    },
    canActivate: [FrapiGuard],
  },
  {
    path: 'safety',
    loadChildren: () =>
      import('./modules/safety/safety.module').then((m) => m.SafetyModule),
    data: {
      claims: [{ claim: Claim.allowReadNodes, claimValue: true }],
      pageMetadata: {
        title: 'Safety',
        icon: 'lock',
        img: 'safety.png',
        showOnHomepage: true,
      },
    },
    canActivate: [FrapiGuard],
  },
  {
    path: 'live-inventory',
    loadChildren: () =>
      import('./modules/live-inventory/live-inventory.module').then(
        (m) => m.LiveInventoryModule
      ),
    data: {
      claims: [
        { claim: Claim.internal, claimValue: true },
        { claim: Claim.allowReadLiveInventory, claimValue: true },
      ],
      pageMetadata: {
        title: 'Live Inventory',
        icon: 'food_bank',
        img: 'live-inventory.png',
        showOnHomepage: true,
      },
    },
    canActivate: [FrapiGuard],
  },
  {
    path: 'pim',
    loadChildren: () =>
      import('./modules/pim/pim.module').then((m) => m.PimModule),
    data: {
      claims: [
        { claim: Claim.internal, claimValue: true },
        { claim: Claim.allowModifyInventory, claimValue: true },
      ],
      pageMetadata: {
        title: 'PIM',
        icon: 'food_bank',
        img: 'pim.jpg',
        showOnHomepage: true,
      },
    },
  },
  {
    path: 'abis',
    loadChildren: () =>
      import('./modules/abis/abis.module').then(
        (m) => m.AutoBagInspectionModule
      ),
    data: {
      claims: [{ claim: Claim.allowModifyDispenseEvent, claimValue: true }],
      pageMetadata: {
        title: 'ABIS',
        icon: 'shopping_bag',
        img: 'abis.jpg',
        showOnHomepage: true,
      },
    },
  },
  {
    path: 'roverwatch',
    loadChildren: () =>
      import('./modules/roverwatch/roverwatch.module').then(
        (m) => m.RoverwatchModule
      ),
    data: {
      claims: [{ claim: Claim.allowReadLFR, claimValue: true }],
      pageMetadata: {
        title: 'Roverwatch',
        icon: 'map',
        img: 'roverwatch.jpg',
        showOnHomepage: true,
      },
    },
    canActivate: [FrapiGuard],
  },
  {
    path: 'map-editor',
    loadChildren: () =>
      import('./modules/map-editor/map-editor.module').then(
        (m) => m.MapEditorModule
      ),
    data: {
      claims: [{ claim: Permission.allowMapEditing, claimValue: true }],
      pageMetadata: {
        title: 'Map Editor',
        icon: 'edit_location',
        img: 'map-edit.png',
        showOnHomepage: true,
      },
    },
    canActivate: [FrapiGuard],
  },
  {
    path: 'stock-room',
    loadChildren: () =>
      import('./modules/stock-room/stock-room.module').then(
        (m) => m.StockRoomModule
      ),
    data: {
      claims: [{ claim: Claim.allowReadStockRoom, claimValue: true }],
      pageMetadata: {
        title: 'Stockroom',
        icon: 'home_work',
        img: 'stockroom.jpg',
        showOnHomepage: true,
      },
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'push-mode-induction',
    loadChildren: () =>
      import('./modules/push-mode-induction/push-mode-induction.module').then(
        (m) => m.PushModeInductionModule
      ),
    data: {
      claims: [
        { claim: Claim.internal, claimValue: true },
        { claim: Claim.allowPubsub, claimValue: true },
      ],
      pageMetadata: {
        title: 'Push Mode Induction',
        icon: 'kitchen',
        img: 'push.jpg',
        showOnHomepage: true,
      },
    },
    canActivate: [FrapiGuard],
  },
  {
    path: 'induction-loops',
    loadChildren: () =>
      import('./modules/induction-loops/induction-loops.routes').then(
        (m) => m.INDUCTION_LOOPS_ROUTES
      ),
    data: {
      claims: [
        { claim: Claim.internal, claimValue: true },
        { claim: Claim.allowReadVLS, claimValue: true },
      ],
      pageMetadata: {
        title: 'Induction Loops',
        icon: 'repartition',
        img: 'loop.jpg',
        showOnHomepage: true,
      },
    },
    canActivate: [FrapiGuard],
  },
  {
    path: 'mars',
    loadChildren: () =>
      import('./modules/mars/mars.routes').then((m) => m.MARS_ROUTES),
    data: {
      claims: [
        { claim: Claim.internal, claimValue: true },
        { claim: Claim.allowReadVLS, claimValue: true },
      ],
      pageMetadata: {
        title: 'MARS Storage',
        icon: 'food_bank',
        img: 'mars.svg',
        showOnHomepage: true,
      },
    },
    canActivate: [FrapiGuard],
  },
  {
    path: 'vls',
    loadChildren: () =>
      import('./modules/vls/vls.module').then((m) => m.VlsModule),
    data: {
      claims: [
        { claim: Claim.internal, claimValue: true },
        { claim: Claim.allowModifyVLS, claimValue: true },
      ],
      pageMetadata: {
        title: 'VLS',
        icon: 'food_bank',
        img: 'vlss.jpg',
        showOnHomepage: true,
      },
    },
    canActivate: [FrapiGuard],
  },
  {
    path: 'trays',
    loadChildren: () =>
      import('./modules/trays/trays.module').then((m) => m.TraysModule),
    data: {
      claims: [{ claim: Claim.internal, claimValue: true }],
      pageMetadata: {
        title: 'Trays',
        icon: 'control_point_duplicate',
        img: 'trays.jpg',
        showOnHomepage: true,
      },
    },
    canActivate: [FrapiGuard],
  },
  {
    path: 'lfrs',
    loadChildren: () =>
      import('./modules/lfrs/lfrs.routes').then((m) => m.LFRS_ROUTES),
    data: {
      claims: [
        { claim: Claim.internal, claimValue: true },
        { claim: Claim.allowReadLFR, claimValue: true },
        { claim: Claim.allowModifyLFR, claimValue: true },
      ],
      pageMetadata: {
        title: 'LFRs',
        icon: 'shopping_cart',
        img: 'lfrs.jpg',
        showOnHomepage: true,
      },
    },
    canActivate: [FrapiGuard],
  },
  {
    path: 'bag-reload-station',
    loadChildren: () =>
      import('./modules/bag-reload-station/bag-reload-station.routes').then(
        (m) => m.BAG_RELOAD_STATION_ROUTES
      ),
    data: {
      claims: [
        { claim: Claim.internal, claimValue: true },
        { claim: Claim.allowBagInspection, claimValue: true },
      ],
      pageMetadata: {
        title: 'Bag Reload Station',
        icon: 'kitchen',
        img: 'bag-reload-station.jpg',
        showOnHomepage: true,
      },
    },
  },
  {
    path: 'bag-inspection',
    loadChildren: () =>
      import('./modules/delivery-dashboard/delivery-dashboard.module').then(
        (m) => m.DeliveryDashboardModule
      ),
    data: {
      claims: [
        { claim: Claim.internal, claimValue: true },
        { claim: Claim.allowBagInspection, claimValue: true },
      ],
      pageMetadata: {
        title: 'Bag Inspection',
        icon: 'shopping_bag',
        img: 'bis.jpg',
        showOnHomepage: true,
      },
    },
  },
  {
    path: 'orders',
    loadChildren: () =>
      import('./modules/orders/orders.module').then((m) => m.OrdersModule),
    data: {
      claims: [{ claim: Claim.allowGetOrders, claimValue: true }],
      pageMetadata: {
        title: 'Dashboard Orders',
        icon: 'local_shipping',
        img: 'orders.jpg',
        showOnHomepage: true,
      },
    },
  },
  {
    path: 'carts',
    loadChildren: () =>
      import('./modules/cart/carts/carts.module').then((m) => m.CartsModule),
    data: {
      claims: [
        { claim: Claim.internal, claimValue: true },
        { claim: Claim.allowAllCartRetrieval, claimValue: true },
      ],
      pageMetadata: {
        title: 'Carts',
        icon: 'shopping_cart',
        img: 'carts.jpg',
        showOnHomepage: true,
      },
    },
  },
  {
    path: 'simulation',
    loadChildren: () =>
      import('./modules/simulation/simulation.module').then(
        (m) => m.SimulationModule
      ),
    data: {
      claims: [
        { claim: Claim.internal, claimValue: true },
        { claim: Claim.allowCreateSimulation, claimValue: true },
      ],
      pageMetadata: {
        title: 'Simulation',
        icon: 'grid_on',
        img: 'simulation.jpg',
        showOnHomepage: true,
      },
    },
  },
  {
    path: 'vendorstore',
    loadChildren: () =>
      import('./modules/vendorstore/vendorstore.module').then(
        (m) => m.VendorStoreModule
      ),
    data: {
      claims: [
        { claim: Claim.internal, claimValue: true },
        { claim: Claim.allowReadStockItemReservation, claimValue: true },
      ],
      pageMetadata: {
        title: 'Vendor Stores',
        icon: 'store',
        img: 'vendorstore.jpg',
        showOnHomepage: true,
      },
    },
  },
  {
    path: 'pubsub',
    loadChildren: () =>
      import('./modules/pubsub/pubsub.module').then((m) => m.PubsubModule),
    data: {
      claims: [
        { claim: Claim.internal, claimValue: true },
        { claim: Claim.allowPubsub, claimValue: true },
      ],
      pageMetadata: {
        title: 'Pubsub',
        icon: 'settings',
        img: 'pubsub.jpg',
        showOnHomepage: true,
      },
    },
  },
  {
    path: 'remote-tray-audit',
    loadChildren: () =>
      import('./modules/remote-tray-audit/remote-tray-audit.routes').then(
        (m) => m.REMOTE_TRAY_AUDIT_ROUTES
      ),
    data: {
      claims: [
        { claim: Claim.internal, claimValue: true },
        { claim: Claim.allowTrayAudit, claimValue: true },
      ],
      pageMetadata: {
        title: 'Remote Tray Audit',
        icon: 'visibility',
        img: 'audit.jpg',
        showOnHomepage: true,
      },
    },
    canActivate: [FrapiGuard],
  },
  {
    path: 'video',
    loadChildren: () =>
      import('./modules/video/video.module').then((m) => m.VideoModule),
    data: {
      claims: [
        { claim: Claim.internal, claimValue: true },
        { claim: Claim.allowModifyVLS, claimValue: true },
      ],
      pageMetadata: {
        title: 'Video',
        icon: 'videocam',
        img: 'camera.png',
        showOnHomepage: true,
      },
    },
    canActivate: [FrapiGuard],
  },
  {
    path: 'live-orders',
    loadChildren: () =>
      import('./modules/live-orders/live-orders.module').then(
        (m) => m.LiveOrdersModule
      ),
    data: {
      claims: [
        { claim: Claim.internal, claimValue: true },
        { claim: Claim.allowGetOrders, claimValue: true },
      ],
      pageMetadata: {
        title: 'Live Orders',
        icon: 'autorenew',
        img: 'liveorders.png',
        showOnHomepage: true,
      },
    },
    canActivate: [FrapiGuard],
  },
  {
    path: 'dispenses',
    loadChildren: () =>
      import('./modules/dispense/dispense.module').then(
        (m) => m.DispenseModule
      ),
    data: {
      claims: [
        { claim: Claim.internal, claimValue: true },
        { claim: Claim.allowReadDispense, claimValue: true },
      ],
      pageMetadata: {
        title: 'Dispenses',
        img: 'dispense.jpg',
        icon: 'system_update_alt',
        showOnHomepage: true,
      },
    },
    canActivate: [FrapiGuard],
  },
  {
    path: 'suppliers',
    loadChildren: () =>
      import('./modules/supplier/supplier.module').then(
        (m) => m.SupplierModule
      ),
    data: {
      claims: [
        { claim: Claim.internal, claimValue: true },
        { claim: Claim.allowModifyInventory, claimValue: true },
      ],
      pageMetadata: {
        title: 'Suppliers',
        icon: 'pan_tool',
        img: 'supplier.png',
        showOnHomepage: true,
      },
    },
  },
  {
    path: 'real-time-induction-validation',
    loadChildren: () =>
      import(
        './modules/real-time-induction-validation/real-time-induction-validation.module'
      ).then((m) => m.RealTimeInductionValidationModule),
    data: {
      claims: [
        { claim: Claim.internal, claimValue: true },
        { claim: Claim.allowModifyInventory, claimValue: true },
      ],
      pageMetadata: {
        title: 'Induction Validation',
        icon: 'kitchen',
        img: 'induction_validation.jpg',
        showOnHomepage: true,
      },
    },
  },
  {
    path: 'thermal',
    loadChildren: () =>
      import('./modules/thermal-sensors/thermal-sensors.module').then(
        (m) => m.ThermalSensorModule
      ),
    data: {
      claims: [
        { claim: Claim.internal, claimValue: true },
        { claim: Claim.allowModifyThermalSensors, claimValue: true },
      ],
      pageMetadata: {
        title: 'Thermal Sensors',
        icon: 'reorder',
        img: 'thermal.jpg',
        customPath: 'thermal/sensors',
        showOnHomepage: true,
      },
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'feature-flags',
    loadChildren: () =>
      import('./modules/feature-flags/feature-flags.module').then(
        (m) => m.FeatureFlagsModule
      ),
    data: {
      claims: [
        { claim: Claim.internal, claimValue: true },
        { claim: Claim.allowModifyFeatureFlags, claimValue: true },
      ],
      pageMetadata: {
        title: 'Feature Flags',
        icon: 'flags',
        img: 'flags.jpg',
        showOnHomepage: true,
      },
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'simulation-controls',
    loadChildren: () =>
      import('./modules/simulation-controls/simulation-controls.module').then(
        (m) => m.SimulationControlsModule
      ),
    data: {
      claims: [
        { claim: Claim.internal, claimValue: true },
        { claim: Claim.allowCreateSimulation, claimValue: true },
      ],
      pageMetadata: {
        title: 'Simulation Controls',
        icon: 'settings',
        showOnHomepage: false,
      },
    },
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./modules/users/users.module').then((m) => m.UsersModule),
    data: {
      claims: [
        { claim: Claim.internal, claimValue: true },
        { claim: Claim.allowReadAdminUser, claimValue: true },
      ],
      pageMetadata: {
        title: 'Users',
        icon: 'account_circle',
        img: 'users.jpg',
        showOnHomepage: true,
      },
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'seed',
    loadChildren: () =>
      import('./modules/seed/seed.module').then((m) => m.SeedModule),
    data: {
      claims: [
        { claim: Claim.internal, claimValue: true },
        { claim: Claim.allowCreateSimulation, claimValue: true },
      ],
      pageMetadata: {
        title: 'Generate Seed File',
        icon: 'account_circle',
        img: 'seed.jpg',
        showOnHomepage: true,
      },
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'hog',
    loadChildren: () =>
      import('./modules/hog/hog.module').then((m) => m.HogModule),
    data: {
      claims: [{ claim: Claim.allowReadHOGTask, claimValue: true }],
      pageMetadata: {
        title: 'Hog',
        icon: 'local_convenience_store',
        img: 'king_hog.png',
        showOnHomepage: true,
      },
    },
  },
  {
    path: 'delivery-windows',
    loadChildren: () =>
      import('./modules/delivery-window/delivery-window.module').then(
        (m) => m.DeliveryWindow
      ),
    data: {
      claims: [
        { claim: Claim.internal, claimValue: true },
        { claim: Claim.allowReadDeliveryWindows, claimValue: true },
      ],
      pageMetadata: {
        title: 'Delivery Windows',
        icon: 'date_range',
        img: 'delivery_window.png',
        showOnHomepage: true,
      },
    },
  },
];

export const customPageRoutes: PageRoute[] = [
  {
    path: 'induction',
    loadChildren: () =>
      import('./modules/induction/induction.module').then(
        (m) => m.InductionModule
      ),
    data: {
      claims: [{ claim: Claim.allowModifyVLS, claimValue: true }],
      laborJobTypes: [LaborJobType.Shopping, LaborJobType.Induction],
      pageMetadata: {
        title: 'Induction',
        icon: 'login',
        img: 'induction.jpg',
        showOnHomepage: true,
      },
    },
    canActivate: [FrapiGuard],
  },
];

const routes: Routes = [
  ...customPageRoutes,
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      ...pageRoutes.filter((route) => route.path !== null),
      {
        path: 'cart/:id',
        loadChildren: () =>
          import('./modules/cart/cartDetails/cart.module').then(
            (m) => m.CartModule
          ),
      },
      {
        path: 'unauthorized',
        loadChildren: () =>
          import('./modules/unauthorized/unauthorized.module').then(
            (m) => m.UnauthorizedModule
          ),
      },
      {
        path: 'machines',
        loadChildren: () =>
          import('./modules/machines/machines.routes').then(
            (m) => m.MACHINE_ROUTES
          ),
      },
      {
        path: 'inventory',
        loadChildren: () =>
          import('./modules/inventory/inventory.module').then(
            (m) => m.InventoryModule
          ),
        canActivate: [FrapiGuard],
      },
      {
        path: 'recovery-wizard',
        loadChildren: () =>
          import('./modules/recovery-wizard/recovery-wizard.routes').then(
            (m) => m.RECOVERY_WIZARD_ROUTES
          ),
        data: {
          claims: [{ claim: Claim.internal, claimValue: true }],
        },
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: CustomPreloadingStrategy,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
