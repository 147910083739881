import { catchError, Observable, throwError } from 'rxjs';
import { APIError, APIErrorSource } from './api-error';

const frapiTraceIdHeader = 'X-Frapi-Trace-Id';
const cloudTraceIdHeader = 'X-Cloud-Trace-Id';

export const errorHandler = (
  fn: (error: any) => boolean,
  errorMessage: string | ((error: any) => string)
) => {
  return catchError((error) => {
    if (fn(error)) {
      let source: APIErrorSource;
      let traceId: string | undefined;
      if (error.headers?.has(frapiTraceIdHeader)) {
        source = 'FRAPI';
        traceId = error.headers?.get(frapiTraceIdHeader);
      }
      if (error.headers?.has(cloudTraceIdHeader)) {
        source = 'Cloud API';
        traceId = error.headers?.get(cloudTraceIdHeader);
      }
      return throwError(
        () =>
          new APIError(
            typeof errorMessage === 'string'
              ? errorMessage
              : errorMessage(error),
            error.status,
            error,
            source,
            traceId
          )
      );
    }
    return throwError(() => error);
  });
};

export const handle404 = <T>(apiCall: Observable<T>) => {
  return apiCall.pipe(
    errorHandler((error) => error.status === 404, 'Not found.')
  ) as Observable<T>;
};

const handle403 = <T>(apiCall: Observable<T>) => {
  return apiCall.pipe(
    errorHandler(
      (error) => error.status === 403,
      (error) =>
        `You do not have permissions. Try logging out then logging back in again. ${error.url}`
    )
  ) as Observable<T>;
};

export const handleApiError = <T>(apiCall: Observable<T>) => {
  return apiCall.pipe(handle404, handle403);
};
