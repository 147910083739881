<div
  class="sidenav-container"
  [class.closed]="!menuIsOpen"
  [class.open]="menuIsOpen"
  [style.background-color]="sideNavBackgroundColor$ | async"
>
  <mat-nav-list class="nav-list-container">
    <div>
      <div
        routerLink="/"
        class="logo"
        [style.background-color]="logoAreaBackgroundColor$ | async"
      >
        <a [routerLink]="['']">
          <img
            *ngIf="menuIsOpen; else menuIsNotOpen"
            [src]="fullLogoUrl$ | async"
            class="full-logo"
            alt="Logo"
          />
          <ng-template #menuIsNotOpen>
            <img
              src="assets/images/logo_white_collapsed.svg"
              alt="Logo"
              class="short-logo"
            />
          </ng-template>
        </a>
      </div>
      <div class="saved-list-items">
        <a
          mat-list-item
          *ngFor="let nav of navService.pinnedNavItems | async"
          (click)="clickedLink.emit(true)"
          [routerLink]="nav.path"
          [routerLinkActive]="['active-item']"
        >
          <mat-icon>{{ nav.icon }}</mat-icon>
          <span>{{ nav.title }}</span>
        </a>
        <a
          *ngIf="isSimulation"
          mat-list-item
          (click)="clickedLink.emit(true)"
          routerLink="/simulation-controls"
          [routerLinkActive]="['active-item']"
        >
          <mat-icon>settings</mat-icon>
          <span>Simulation Controls</span>
        </a>
        <a mat-list-item [href]="accountManagementUrl">
          <mat-icon>account_circle</mat-icon>
          <span>Account</span>
        </a>
      </div>
    </div>
    <div>
      <a mat-list-item (click)="toggleMenu()">
        <mat-icon>{{ menuIsOpen ? 'chevron_left' : 'chevron_right' }}</mat-icon>
        <span>Collapse</span>
      </a>
      <a mat-list-item (click)="openSettings()">
        <mat-icon>settings</mat-icon>
        <span>Settings</span>
      </a>
      <a mat-list-item (click)="onLogout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>Logout</span>
      </a>
      <div class="vpn-status" *ngIf="!menuIsOpen">
        <img *ngIf="!frapiVersion" src="assets/images/vpn-disconnected.svg" />
        <img *ngIf="frapiVersion" src="assets/images/vpn-connected.svg" />
      </div>
      <div class="env-name" *ngIf="menuIsOpen">
        <div>
          {{ envDisplayName }}
        </div>
        <div class="web-version">
          dashboard: {{ currentVersion | webVersion }}
        </div>
        <div *ngIf="frapiVersion" class="frapi-version">
          frapi: {{ frapiVersion }}
        </div>
        <div class="update-web-version" *ngIf="showNewVersionButton">
          <button
            mat-stroked-button
            matTooltip="New version of the web app is available"
            (click)="updateToLatest()"
          >
            Update to {{ newVersion | webVersion }}
          </button>
        </div>
      </div>
    </div>
  </mat-nav-list>
</div>
