const ACCESS_TOKEN_KEY = 'access_token';

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const setAccessToken = (accessToken: string | undefined) => {
  if (accessToken) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  }
};

export const deleteAccessToken = () => {
  window.localStorage.removeItem(ACCESS_TOKEN_KEY);
};
