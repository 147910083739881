import { ReplaySubject } from 'rxjs';

/**
 * The class `OnDestroySubject` extends `ReplaySubject` and provides a way
 * to clean up observables when a component is destroyed.
 *
 * Example usage:
 *
 * ```
 * export class SomeComponent implements OnInit, OnDestroy {
 *    private onDestroy$ = new OnDestroySubject();
 *
 *    data$: Observable<SomeData>;
 *
 *    ngOnInit() {
 *      this.data$ = this.someService.getLongRunningObservable()
 *        .pipe(
 *          takeUntil(this.onDestroy$)
 *        );
 *    }
 *
 *    ngOnDestroy() {
 *      this.onDestroy$.complete();
 *    }
 * }
 * ```
 */
export class OnDestroySubject extends ReplaySubject<void> {
  constructor() {
    super(1);
  }

  /**
   * Calls the next and complete methods of the underlying ReplaySubject.
   */
  next() {
    this.complete();
  }

  /**
   * Calls the next and complete methods of the underlying ReplaySubject.
   */
  complete() {
    super.next();
    super.complete();
  }
}
