<app-loader
  [color]="'primary'"
  [mode]="'indeterminate'"
  [diameter]="50"
  [backdropEnabled]="true"
  [positionGloballyCenter]="true"
  [displayProgressSpinner]="isLoaderVisible"
>
</app-loader>
<router-outlet></router-outlet>
