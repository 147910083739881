<div>
  <h1 mat-dialog-title>
    {{ title }}
  </h1>
  <div mat-dialog-content class="content">
    <p class="warning-message">{{ message }}</p>
    <br />
  </div>
  <div mat-dialog-actions align="end">
    <button
      mat-raised-button
      color="primary"
      [mat-dialog-close]="true"
      cdkFocusInitial
    >
      {{ cta }}
    </button>
  </div>
</div>
