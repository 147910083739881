export enum Claim {
  allowModifyInventory = 'allowModifyInventory',
  allowEtl = 'allowEtl',
  allowPimDataImportExport = 'allowPimDataImportExport',
  allowReadFeatureFlags = 'allowReadFeatureFlags',
  allowModifyFeatureFlags = 'allowModifyFeatureFlags',
  allowModifyTrayFit = 'allowModifyTrayFit',
  allowReadTrayFit = 'allowReadTrayFit',
  allowModifyStockRoom = 'allowModifyStockRoom',
  allowReadStockRoom = 'allowReadStockRoom',
  allowReadStockItem = 'allowReadStockItem',
  allowModifyStockItem = 'allowModifyStockItem',
  allowModifyTrayRecipe = 'allowModifyTrayRecipe',
  allowReadTrayRecipe = 'allowReadTrayRecipe',
  allowAllBizEventActions = 'allowAllBizEventActions',
  allowQueryAllOrders = 'allowQueryAllOrders',
  allowCrossOriginAccess = 'allowCrossOriginAccess',
  allowExpireAllStaleInventory = 'allowExpireAllStaleInventory',
  allowModifyCart = 'allowModifyCart',
  allowResetAllOrder = 'allowResetAllOrder',
  allowCartOperation = 'allowCartOperation',
  allowOrderPlace = 'allowOrderPlace',
  allowGetOrderByUser = 'allowGetOrderByUser',
  allowModifyShopping = 'allowModifyShopping',
  allowGetShopping = 'allowGetShopping',
  allowCreateUser = 'allowCreateUser',
  allowGetUser = 'allowGetUser',
  allowAddRemoveItemFromCart = 'allowAddRemoveItemFromCart',
  allowGenerateDemoOrder = 'allowGenerateDemoOrder',
  allowOrderReport = 'allowOrderReport',
  allowUpdateUserPermission = 'allowUpdateUserPermission',
  allowDeleteGroupRoutes = 'allowDeleteGroupRoutes',
  allowPubsub = 'allowPubsub',
  allowPubsubByTopic = 'allowPubsubByTopic',
  allowDeliveryOrder = 'allowDeliveryOrder',
  allowAllCartRetrieval = 'allowAllCartRetrieval',
  allowInventoryByCart = 'allowInventoryByCart',
  allowBagInspection = 'allowBagInspection',
  allowCreateSimulation = 'allowCreateSimulation',
  allowTakeProdSnapshot = 'allowTakeProdSnapshot',
  allowModifyThermalSensors = 'allowModifyThermalSensors',
  allowSnapshotShelfGroup = 'allowSnapshotShelfGroup',
  allowReadLiveInventory = 'allowReadLiveInventory',
  allowModifyRefund = 'allowModifyRefund',
  allowReadDoordashMarketplace = 'allowReadDoordashMarketplace',
  allowModifyDoordashMarketplace = 'allowModifyDoordashMarketplace',
  // Roverwatch 2 Permissions
  allowToggleTravelNodeStatus = 'allowToggleTravelNodeStatus',
  // allowEditFactoryObject = 'allowEditFactoryObject', deprecated
  allowReadABL = 'allowReadABL',
  allowReadLFR = 'allowReadLFR',
  allowModifyLFR = 'allowModifyVLS',
  allowReadAdminUser = 'allowReadAdminUser',
  allowWriteAdminUser = 'allowWriteAdminUser',
  allowModifyProblematicDispenseQueue = 'allowModifyProblematicDispenseQueue',
  allowGetOrders = 'allowGetOrders',
  allowGetVendorStores = 'allowGetVendorStores',
  allowReadDispenseEvent = 'allowReadDispenseEvent',
  allowModifyDispenseEvent = 'allowModifyDispenseEvent',
  allowModifyFirmware = 'allowModifyFirmware',
  allowReadNodes = 'allowReadNodes',
  allowModifyNodes = 'allowModifyNodes',
  allowReadVLS = 'allowReadVLS',
  allowModifyVLS = 'allowModifyVLS',
  allowPrioritizePickupTime = 'allowPrioritizePickupTime',
  allowTrayAudit = 'allowTrayAudit',
  allowReadDispense = 'allowReadDispense',
  allowModifyDispense = 'allowModifyDispense',
  allowViewDepthCam = 'allowViewDepthCam',
  allowReadStockItemReservation = 'allowReadStockItemReservation',
  allowReadHOGTask = 'allowReadHOGTask',
  allowCreateHOGTask = 'allowCreateHOGTask',
  allowCancelHOGTask = 'allowCancelHOGTask',
  allowPauseHOGTask = 'allowPauseHOGTask',
  allowEditHOGTask = 'allowEditHOGTask',
  allowReadDeliveryWindows = 'allowReadDeliveryWindows',
  allowCreateDeliveryWindows = 'allowCreateDeliveryWindows',
  allowEditDeliveryWindows = 'allowEditDeliveryWindows',
  allowDeleteDeliveryWindows = 'allowDeleteDeliveryWindows',
  allowMapEditing = 'allowMapEditing',
  internal = 'internal',
  canChangeSkuReadyForProduction = 'canChangeSkuReadyForProduction',
}
