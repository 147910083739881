import { Component, Input } from '@angular/core';
import {
  LaborJob,
  LaborJobStatus,
  LaborJobType,
} from '@fulfil0518/fulfil-api-libs/laborjob';
import { combineLatestWith, interval, map, Observable, startWith } from 'rxjs';
import { NotifyService } from 'src/app/core/services/notify.service';
import { GlobalLaborJobService } from '../../services/global-labor-job.service';

@Component({
  selector: 'app-global-labor-job-button',
  templateUrl: './global-labor-job-button.component.html',
  styleUrls: ['./global-labor-job-button.component.scss'],
})
export class GlobalLaborJobButtonComponent {
  // @ts-expect-error ts(2564)
  @Input() laborJobType: LaborJobType;

  LaborJobStatus = LaborJobStatus;

  isCompleting = false;
  isStarting = false;

  job: Observable<LaborJob | null> =
    this.globalInductionLaborJobService.jobs.pipe(
      map((jobsMap) => jobsMap.get(this.laborJobType) || null)
    );

  elapsedMinutes: Observable<number> = interval(100).pipe();

  constructor(
    private globalInductionLaborJobService: GlobalLaborJobService,
    private notifyService: NotifyService
  ) {
    const ONE_MINUTE = 60 * 1000;
    this.elapsedMinutes = this.job.pipe(
      combineLatestWith(interval(ONE_MINUTE).pipe(startWith(null))),
      map(([job]) => job),
      map((job) => (Date.now() - (job?.startTime || 0)) / (60 * 1000)),
      map((rawValue) => Math.round(rawValue))
    );
  }

  async start() {
    this.isStarting = true;
    try {
      await this.globalInductionLaborJobService.start(this.laborJobType);
      this.notifyService.showToast('Job started.');
    } catch (e: any) {
      this.notifyService.showToastWithConfirm(
        `An unexpected error occurred. ${e.message}`
      );
    } finally {
      this.isStarting = false;
    }
  }

  async complete() {
    this.isCompleting = true;
    try {
      await this.globalInductionLaborJobService.complete(this.laborJobType);
      this.notifyService.showToast('Job completed.');
    } catch (e: any) {
      this.notifyService.showToastWithConfirm(
        `An unexpected error occurred. ${e.message}`
      );
    } finally {
      this.isCompleting = false;
    }
  }
}
