import { Injectable } from '@angular/core';
import { CloudApiService } from 'src/app/core/services/cloud-api.service';

export interface IOrderPeriodicElement {
  name: string;
  position: number;
  orderNo: number;
  orderPrice: number;
  orderDate: string;
  reOrderCol: any;
}

@Injectable()
export class CartService {
  constructor(private cloudApi: CloudApiService) {}

  /**
   * retrieves the "latest" carts in the database, sorted by creation date.
   * @param limit - max number of carts to retrieve
   */
  retrieveAllCarts(limit = 100) {
    const url = `cart/latest?limit=${limit}`;
    return this.cloudApi.get(url);
  }

  /**
   * get all of the product and inventory information for a given cart
   * @param cartId - id of the cart to get inventory for
   */
  retrieveInventoryForCart(cartId: any) {
    const url = `cart/${cartId}/inventory/complete`;
    return this.cloudApi.get(url);
  }
}
