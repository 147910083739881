import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { get, merge } from 'lodash';
import { Observable, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getAccessToken } from '../helpers/access-token';
import { errorHandler, handleApiError } from './api-error.handler';
import { StartupCheckService } from './startup-check/startup-check.service';

export const handle400 = <T>(apiCall: Observable<T>) => {
  return apiCall.pipe(
    errorHandler(
      (error) => {
        return error.status === 400;
      },
      (error) => get(error, 'error.message', error?.message)
    )
  ) as Observable<T>;
};

@Injectable({
  providedIn: 'root',
})
export class CloudApiService {
  constructor(
    private http: HttpClient,
    private startupCheckService: StartupCheckService
  ) {}

  public authHeaders(): { token: string } | {} {
    const token = getAccessToken();
    if (!token) {
      console.warn('RestApiV2Service: Access token not found.');
      return {};
    }

    return { token };
  }

  public buildUrl(endpoint: any): string {
    return `${environment.API_URL}${endpoint}`;
  }

  queryAbstract<T>(
    endpoint: string,
    // eslint-disable-next-line @typescript-eslint/default-param-last
    headers = {},
    verb: 'get' | 'delete'
  ): Observable<T> {
    const headersWithAuth = merge(headers, this.authHeaders());
    return this.startupCheckService.startupCheck$.pipe(
      switchMap(() =>
        this.http[verb](this.buildUrl(endpoint), {
          headers: headersWithAuth,
        })
      ),
      handleApiError,
      handle400
    ) as Observable<T>;
  }

  saveAbstract<T>(
    endpoint: string,
    body: any,
    // eslint-disable-next-line @typescript-eslint/default-param-last
    headers = {},
    verb: 'post' | 'put' | 'patch'
  ): Observable<T> {
    const headersWithAuth = merge(headers, this.authHeaders());
    return this.startupCheckService.startupCheck$.pipe(
      switchMap(() =>
        this.http[verb]<T>(this.buildUrl(endpoint), body, {
          headers: headersWithAuth,
        })
      ),
      handleApiError,
      handle400
    );
  }

  public get<T>(endpoint: string, headers = {}): Observable<T> {
    return this.queryAbstract<T>(endpoint, headers, 'get');
  }

  public delete<T>(endpoint: string, headers = {}): Observable<T> {
    return this.queryAbstract<T>(endpoint, headers, 'delete');
  }

  public post<T>(endpoint: string, body: any, headers = {}): Observable<T> {
    return this.saveAbstract<T>(endpoint, body, headers, 'post');
  }

  public patch<T>(endpoint: string, body: any, headers = {}): Observable<T> {
    return this.saveAbstract<T>(endpoint, body, headers, 'patch');
  }

  public put<T>(endpoint: string, body: any, headers = {}): Observable<T> {
    return this.saveAbstract<T>(endpoint, body, headers, 'put');
  }
}
