import { CommonModule } from '@angular/common';
import {
  MatLegacySnackBarModule as MatSnackBarModule,
  MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
  MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';

import { Component, HostBinding, Inject } from '@angular/core';
import { SnackBarLinkItem } from 'src/app/core/types';

@Component({
  standalone: true,
  selector: 'app-snackbar-with-link',
  templateUrl: './snackbar-with-link.component.html',
  styleUrls: ['./snackbar-with-link.component.scss'],
  imports: [CommonModule, MatSnackBarModule, MatButtonModule, MatIconModule],
})
export class SnackbarWithLinkComponent {
  @HostBinding('class') class = 'snackbar-with-link';
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      message: string;
      link: SnackBarLinkItem;
      confirmText: string;
      copyText?: string;
    },
    private snackBarRef: MatSnackBarRef<SnackbarWithLinkComponent>
  ) {}

  copyText(text: string) {
    navigator.clipboard.writeText(text);
  }

  dismiss() {
    this.snackBarRef.dismiss();
  }
}
