import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appWidgetMover]',
})
export class WidgetMoverDirective {
  widgetElement: HTMLElement;

  private startX: number | undefined;
  private startY: number | undefined;
  private startBottom: number | undefined;
  private startRight: number | undefined;

  private onMouseUpBound: any;
  private onMouseMoveBound: any;

  constructor(el: ElementRef) {
    this.widgetElement = el.nativeElement;
    this.onMouseUpBound = this.onMouseDown.bind(this);
    this.widgetElement.addEventListener('mousedown', this.onMouseUpBound);
  }
  private onMouseDown(event: any) {
    this.startX = event.clientX;
    this.startY = event.clientY;
    const el = this.widgetElement;
    const style = window.getComputedStyle(el);
    this.startBottom = parseInt(style.bottom, 10);
    this.startRight = parseInt(style.right, 10);
    document.addEventListener('mouseup', this.onMouseUp.bind(this));
    this.onMouseMoveBound = this.onMouseMove.bind(this);
    document.addEventListener('mousemove', this.onMouseMoveBound);
  }

  private onMouseMove = (event: any) => {
    const rightDiff = this.startX! - event.clientX;
    const bottomDiff = this.startY! - event.clientY;
    this.updatePosition(rightDiff, bottomDiff);
  };

  private onMouseUp = () => {
    document.removeEventListener('mousemove', this.onMouseMoveBound);
    document.removeEventListener('mouseup', this.onMouseUpBound);
  };

  updatePosition(rightDiff: number, bottomDiff: number) {
    this.widgetElement.style.right = `${this.startRight! + rightDiff}px`;
    this.widgetElement.style.bottom = `${this.startBottom! + bottomDiff}px`;
  }
}
