import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, shareReplay, take } from 'rxjs';

/**
 * This service is made to track when the app has run all of its startup code
 * Originally we tried ApplicationInitStatus but that unfortunately led to circular dependencies
 * Because this service does not depend on any other services there can't be circular dependencies
 */
@Injectable({
  providedIn: 'root',
})
export class StartupCheckService {
  constructor() {}

  #startupCheck$ = new BehaviorSubject<boolean>(false);
  startupCheck$ = this.#startupCheck$.asObservable().pipe(
    filter((value) => value),
    shareReplay(1),
    take(1)
  );

  emitStarted() {
    this.#startupCheck$.next(true);
  }
}
