import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
} from '@angular/material/legacy-dialog';

export type ConfirmDialogData = {
  cta: string;
  cancelCta: string;
  message: string;
  header?: string;
};

@Component({
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  cta: string;
  cancelCta: string;
  message: string;
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ConfirmDialogData
  ) {
    this.cta = data.cta;
    this.cancelCta = data.cancelCta;
    this.message = data.message;
  }
}
