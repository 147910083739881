import { Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import {
  LaborJob,
  LaborJobStatus,
  LaborJobType,
} from '@fulfil0518/fulfil-api-libs/laborjob';
import _, { isNil } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotifyService } from 'src/app/core/services/notify.service';
import { MultiStoreService } from 'src/app/core/services/multi-store.service';
import {
  LaborJobAFKDialogComponent,
  LaborJobAFKResult,
} from '../components/labor-job-afkdialog/labor-job-afkdialog.component';
import { LaborJobService } from './labor-job.service';
import { LaborJobEventService } from './labor-job-event.service';

const GLOBAL_LABOR_JOBS_KEY = 'LABORJOB:GLOBAL';
const AFK_TIME_TRIGGER = 10 * 60 * 1000; // 10 minute timer
const ALL_JOB_TYPES = [
  LaborJobType.Induction,
  LaborJobType.Receiving,
  LaborJobType.Shopping,
];

/**
 * The global labor job service only tracks one job per job type.
 * It's intended to track a single job for the user across different sessions and/or pages.
 * ex. Tracking a set of induction sessions as one job
 */
@Injectable({
  providedIn: 'root',
})
export class GlobalLaborJobService {
  private _jobs: BehaviorSubject<Map<LaborJobType, LaborJob>> =
    new BehaviorSubject(new Map());
  public jobs: Observable<Map<LaborJobType, LaborJob>> =
    this._jobs.asObservable();

  private timerId: any;
  private afkDialog: MatDialogRef<LaborJobAFKDialogComponent> | null = null;
  private onMouseMove;

  constructor(
    private laborJobService: LaborJobService,
    private dialog: MatDialog,
    private notifyService: NotifyService,
    private laborJobEventService: LaborJobEventService,
    private multiStoreService: MultiStoreService
  ) {
    this.loadJobs();
    // if the user opens another tab then comes back, then we want
    // to reload the jobs from local storage to keep the state the
    // same across browser tabs
    window.addEventListener('focus', () => {
      this.loadJobs();
    });

    this.onMouseMove = this.awayTimerCheck.bind(this);
  }

  getJob(jobType: LaborJobType): LaborJob | null {
    const jobs = this._jobs.getValue();
    return jobs.get(jobType) || null;
  }

  isStarted(jobType: LaborJobType): boolean {
    const jobsMap = this._jobs.getValue();
    return jobsMap.get(jobType)?.status === LaborJobStatus.InProgress;
  }

  async startIfNotStarted(jobType: LaborJobType) {
    if (!this.isStarted(jobType)) {
      return this.start(jobType);
    }
  }

  async start(jobType: LaborJobType): Promise<void> {
    let job = await this.laborJobService.createJob(jobType);
    job = await this.laborJobService.startJob(job.id);

    this.saveJob(job);
    this.awayTimerCheck();
  }

  async sendEvent(jobType: LaborJobType, payload: any) {
    await this.startIfNotStarted(jobType);
    const jobId = this.getJob(LaborJobType.Receiving)?.id;
    if (isNil(jobId)) {
      return;
    }

    await this.laborJobEventService.createEvent(jobId, {
      jobId,
      facility: this.multiStoreService.getCurrentFacilityIdentifier(),
      ...payload,
    });
  }

  async completeAllActiveJobs(): Promise<void[]> {
    const jobIds = this.getActiveJobIds();
    return Promise.all(jobIds.map((id) => this.completeJobById(id)));
  }

  async complete(jobType: LaborJobType): Promise<void> {
    const jobId = this.getJob(jobType)?.id;
    if (_.isNil(jobId)) {
      throw new Error('No job to complete');
    }

    await this.completeJobById(jobId);
  }

  private async completeJobById(
    id: number,
    timeDeductionInMilliseconds?: number
  ) {
    const job = await this.laborJobService.completeJob({
      id,
      timeDeductionInMilliseconds,
    });
    this.saveJob(job);
  }

  getActiveJobIds(): number[] {
    return ALL_JOB_TYPES.filter((l) => this.isStarted(l)).map(
      (l) => this.getJob(l)!.id
    );
  }

  private loadJobs() {
    const storedJobs = localStorage.getItem(GLOBAL_LABOR_JOBS_KEY);

    if (storedJobs) {
      this._jobs.next(new Map(Array.from(JSON.parse(storedJobs))));
    }

    this.awayTimerCheck();
  }

  private saveJob(job: LaborJob) {
    if (_.isNil(job)) {
      throw new Error('The given job was null or undefined.');
    }

    const jobType = job.jobType as LaborJobType;
    if (!jobType) {
      throw new Error(`${jobType} is not a valid job type.`);
    }
    const jobsMap = this._jobs.getValue();
    jobsMap.set(jobType, job);
    this._jobs.next(jobsMap);
    localStorage.setItem(
      GLOBAL_LABOR_JOBS_KEY,
      JSON.stringify(Array.from(this._jobs.getValue().entries()))
    );

    this.awayTimerCheck();
  }

  /// Ends user tracking.
  private cancelAwayTimer() {
    document.removeEventListener('mousemove', this.onMouseMove);
    clearTimeout(this.timerId);
    this.timerId = null;
  }

  private isAnyJobStarted(): boolean {
    const startedJob = ALL_JOB_TYPES.find((jobType) => this.isStarted(jobType));
    return startedJob !== null && startedJob !== undefined;
  }

  /// Begins tracking user movement, if there is no movement within 10 minutes it will ask the user to end the session.
  /// Resets the timer if one is already active.
  private startAwayTimer() {
    this.cancelAwayTimer();
    document.addEventListener('mousemove', this.onMouseMove);
    this.timerId = setTimeout(this.onAFKTrigger.bind(this), AFK_TIME_TRIGGER);
  }

  private onAFKTrigger() {
    // We've already been afk, lets just auto end for them.
    if (this.afkDialog) {
      this.autoEnd();
    } else {
      this.openAFKDialog();
    }
  }

  private async autoEnd() {
    try {
      await this.completeAllJobsWithTimeDeduction(AFK_TIME_TRIGGER * 2);
      this.afkDialog?.close();
      this.afkDialog = null;
      this.awayTimerCheck();
    } catch (e: any) {
      this.notifyService.showToastWithConfirm(
        `An unexpected error occurred while automatically ending the session. ${e.message}`
      );
    }
  }

  private async completeAllJobsWithTimeDeduction(
    timeDeductionInMilliseconds: number
  ) {
    const allActiveJobs = this.getActiveJobIds();
    const promises = allActiveJobs.map((id) =>
      this.completeJobById(id, timeDeductionInMilliseconds)
    );
    await promises;
    this.awayTimerCheck();
  }

  private awayTimerCheck() {
    if (this.isAnyJobStarted()) {
      this.startAwayTimer();
    } else {
      this.cancelAwayTimer();
    }
  }

  private openAFKDialog() {
    this.awayTimerCheck();
    this.afkDialog = this.dialog.open(LaborJobAFKDialogComponent, {
      data: {
        minutes: Math.round(((AFK_TIME_TRIGGER / (60 * 1000)) * 100) / 100),
      },
    });
    this.afkDialog
      .afterClosed()
      .subscribe(async (result?: LaborJobAFKResult) => {
        // Dialog was closed
        this.afkDialog = null;
        if (!result) {
          return;
        }

        const { resume } = result;

        if (resume) {
          this.awayTimerCheck();
        } else {
          try {
            await this.completeAllJobsWithTimeDeduction(AFK_TIME_TRIGGER);
            this.awayTimerCheck();
          } catch (e: any) {
            this.notifyService.showToastWithConfirm(
              `An unexpected error occurred. ${e.message}`
            );
            this.awayTimerCheck();
          }
        }
      });
  }
}
