import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { CartService } from '../modules/cart/cart.service';
import { DispenseEventService } from '../modules/abis/dispense-event.service';
import { ConfirmDialogComponent } from '../shared/components/confirm-dialog/confirm-dialog.component';
import { LoaderComponent } from '../shared/components/loader/loader.component';
import { LayoutModule } from '../shared/components/layout/layout.module';
import { AuthGuard, PubsubService } from './services';
import { JwtInterceptor } from './interceptors';
import { CustomPreloadingStrategy } from './strategy';
import { OrderService } from './services/order.service';
import { ImageEnlargeFooterComponent } from './components/image-enlarge-footer/image-enlarge-footer.component';
import { ImageModalComponent } from './components/image-modal/image-modal.component';
import { BagService } from './services/bag.service';

@NgModule({
  imports: [
    CommonModule,
    // required by AppComponent - I had a hard time converting AppComponent to standalone
    LoaderComponent,
    // required by AppVersionService
    MatSnackBarModule,
    // GlobalLaborJobService -> LaborJobService -> NotifyService
    MatDialogModule,
    // required by NotifyService
    ConfirmDialogComponent,
    LayoutModule,
    ImageEnlargeFooterComponent,
    MatLegacyButtonModule,
  ],
  providers: [
    AuthGuard,
    PubsubService,
    CustomPreloadingStrategy,
    CartService,
    OrderService,
    BagService,
    DispenseEventService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  exports: [LoaderComponent],
  declarations: [ImageModalComponent],
})
export class CoreModule {}
